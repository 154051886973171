import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import Thumbnail from '../Thumbnail';
import Swiper from 'react-id-swiper';
import Icon from '../Icon';
import useData from '../../businessLogic/hooks/useData';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import 'swiper/swiper.scss';
import './styles.scss';

const OptionSlider = (props) => {
	const { selections, optionIndex, stateProperty, optionFileInfo } = props;
	const [activeSlide, setActiveSlide] = useState(optionIndex);
	const [activeDescription, setActiveDescription] = useState('');
	const { UpdateStateDataApp, data } = useData();

	const oprtionsSwiperRef = useRef(null);
	const oprtionsSwiperParams = {
		spaceBetween: 0,
		slidesPerView: 1,
		containerClass: 'option-slider__slider-wrapper__swiper',
		speed: 1000,
		initialSlide: optionIndex,
		on: {
			slideChange: function (event) {
				setActiveSlide(event.activeIndex);
			},
		},
	};

	const currentState = get(data, 'state');

	const classes = classNames({
		'option-slider': true,
	});

	const classesPrev = classNames({
		'option-slider__slider-wrapper__prev-btn': true,
		disable: activeSlide === 0,
	});

	const classesNext = classNames({
		'option-slider__slider-wrapper__next-btn': true,
		disable: activeSlide === selections.length - 1,
	});

	const updateStateWithOptions = (state, newSelection) => {
		UpdateStateDataApp({ state, newSelection }, 'configurator');
	};

	const goNext = () => {
		if (oprtionsSwiperRef.current && oprtionsSwiperRef.current.swiper) {
			oprtionsSwiperRef.current.swiper.slideNext();
			setActiveSlide(oprtionsSwiperRef.current.swiper.activeIndex);
		}
	};
	const goPrev = () => {
		if (oprtionsSwiperRef.current && oprtionsSwiperRef.current.swiper) {
			oprtionsSwiperRef.current.swiper.slidePrev();
			setActiveSlide(oprtionsSwiperRef.current.swiper.activeIndex);
		}
	};

	let textPath = undefined;
	if (optionFileInfo) {
		textPath = optionFileInfo;
	} else {
		textPath =
			get(selections, [activeSlide, 'productInfo']) ||
			get(selections, [activeSlide, 'optionInfo']);
	}
	const textFile = `${window.location.origin}${textPath}`;
	const fetchTxtFile = () => {
		if (textPath) {
			fetch(textFile)
				.then((response) => response.text())
				.then((text) => {
					setActiveDescription(text);
				});
		}
	};

	useEffect(() => {
		fetchTxtFile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSlide]);

	return (
		<div className={classes}>
			<div className='option-slider__slider-wrapper'>
				<Swiper {...oprtionsSwiperParams} ref={oprtionsSwiperRef}>
					{selections.map((option, index) => {
						return (
							<div
								className='option-slider__swiper__item swiper-slide'
								key={index}>
								<Thumbnail
									size='large'
									shape='rounded'
									thumbnailData={option}
									detail={false}
									type='detail'
									labelBg={true}
									showName={false}
								/>
							</div>
						);
					})}
				</Swiper>
				<button className={classesPrev} onClick={goPrev}>
					<Icon iconName='arrow-up' size='large' />
				</button>
				<button className={classesNext} onClick={goNext}>
					<Icon iconName='arrow-up' size='large' />
				</button>
			</div>
			<div className='option-slider__buttons'>
				<div
					className='option-slider__buttons__randomize-selection'
					onClick={() => {
						let randomSlide =
							~~(Math.random() * selections.length) + 0;
						oprtionsSwiperRef.current.swiper.slideTo(
							randomSlide,
							1000
						);
					}}>
					<Icon iconName='shuffle' size='normal' />
				</div>
				<div
					className='option-slider__buttons__apply-selection-btn'
					onClick={() => {
						updateStateWithOptions(currentState, {
							[stateProperty]: get(
								selections[activeSlide],
								'name'
							),
						});
					}}>
					Apply selection
				</div>
			</div>
			<div className='option-slider__name'>
				{get(selections[activeSlide], 'displayName')}
			</div>
			{activeDescription !== '' && (
				<div className='option-slider__description'>
					<p>{activeDescription}</p>
				</div>
			)}
		</div>
	);
};

OptionSlider.propTypes = {
	selections: PropTypes.array,
	optionIndex: PropTypes.number,
	stateProperty: PropTypes.string,
};

OptionSlider.defaultProps = {
	selections: [],
	optionIndex: 0,
};

export default OptionSlider;
