import React from 'react';
import CookieConsent, { setWithExpiry, getWithExpiry } from "@arcware/cookie-consent";

const cookies = {
	necessary: [
		{ name: 'CookieConsent', provider: { name: "Conform", link: window.location.href }, purpose: 'To remember the choice of cookie consent' }
	],
	settings: [],
	statistics: [],
	marketing: []
} 

const enableGTAG = () => {
	window.gtag('consent', 'update', {
		'ad_storage': 'granted',
		'analytics_storage': 'granted'
	});
}

const useNecessary = () => {
	setWithExpiry('CookieConsent', { settings: false, necessary: false, marketing: false }, 60*60*24*90);
}

const useSelected = (selected) => {
	if(selected.statistics) {
		enableGTAG();
	}
	setWithExpiry('CookieConsent', selected, 60*60*24*90);
}

const useAll = () => {
	enableGTAG();
	setWithExpiry('CookieConsent', {settings: true, necessary: true, marketing: true}, 60*60*24*90);
}

const selectedCookies = getWithExpiry('CookieConsent');
if(selectedCookies?.statistics) {
	enableGTAG();
}

const CookieBanner = () => {
	return (
		<>
            {!selectedCookies && <CookieConsent data={cookies} checkBoxColor="#43a047" necessaryCallback={useNecessary} selectedCallback={useSelected} allCallback={useAll} plugins={[{ name: 'injectGTM', providers: ['gtag', 'ga', 'gtagAnalytics'] }]} />}
        </>
	)
};

export default CookieBanner;
