import React from 'react';

import './styles.scss';

const Privacy = () => {
	return (
		<div className='privacy'>
			<p>
				Conform Collection AB is committed to protecting your privacy
				and developing technology that gives you the most powerful and
				safe online experience. This Statement of Privacy applies to the
				Conform Collection AB site and governs data collection and
				usage. By using the Conform Collection AB site, you consent to
				the data practices described in this statement.
				<br />
				<br />
				<b>Collection of your Personal Information</b>
				<br />
				<br />
				Conform Collection AB collects personally identifiable
				information, such as your email address, name, home or work
				address or telephone number. Conform Collection AB also collects
				anonymous demographic information, which is not unique to you,
				such as your ZIP code, age, gender, preferences, interests and
				favorites.
				<br />
				<br />
				There is also information about your computer hardware and
				software that is automatically collected by Conform Collection
				AB. This information can include: your IP address, browser type,
				domain names, access times and referring website addresses. This
				information is used by Conform Collection AB for the operation
				of the service, to maintain quality of the service, and to
				provide general statistics regarding use of the Conform
				Collection AB site.
				<br />
				<br />
				Please keep in mind that if you directly disclose personally
				identifiable information or personally sensitive data through
				Conform Collection AB public message boards, this information
				may be collected and used by others. Note: Conform Collection AB
				does not read any of your private online communications.
				<br />
				<br />
				Conform Collection AB encourages you to review the privacy
				statements of Web sites you choose to link to from Conform
				Collection AB so that you can understand how those Web sites
				collect, use and share your information. Conform Collection AB
				is not responsible for the privacy statements or other content
				on Web sites outside of the Conform Collection AB and Conform
				Collection AB family of Web sites.
				<br />
				<br />
				<b>Use of your Personal Information Conform</b>
				<br />
				<br />
				Collection AB collects and uses your personal information to
				operate the Conform Collection AB Web site and deliver the
				services you have requested. Conform Collection AB also uses
				your personally identifiable information to inform you of other
				products or services available from Conform Collection AB and
				its affiliates. Conform Collection AB may also contact you via
				surveys to conduct research about your opinion of current
				services or of potential new services that may be offered.
				<br />
				<br />
				Conform Collection AB does not sell, rent or lease its customer
				lists to third parties. Conform Collection AB may, from time to
				time, contact you on behalf of external business partners about
				a particular offering that may be of interest to you. In those
				cases, your unique personally identifiable information (e-mail,
				name, address, telephone number) is not transferred to the third
				party. In addition, Conform Collection AB may share data with
				trusted partners to help us perform statistical analysis, send
				you email or postal mail, provide customer support, or arrange
				for deliveries. All such third parties are prohibited from using
				your personal information except to provide these services to
				Conform Collection AB, and they are required to maintain the
				confidentiality of your information.
				<br />
				<br />
				Conform Collection AB does not use or disclose sensitive
				personal information, such as race, religion, or political
				affiliations, without your explicit consent.
				<br />
				<br />
				Conform Collection AB keeps track of the Web sites and pages our
				customers visit within Conform Collection AB, in order to
				determine what Conform Collection AB services are the most
				popular. This data is used to deliver customized content and
				advertising within Conform Collection AB to customers whose
				behavior indicates that they are interested in a particular
				subject area.
				<br />
				<br />
				Conform Collection AB Web sites will disclose your personal
				information, without notice, only if required to do so by law or
				in the good faith belief that such action is necessary to: (a)
				conform to the edicts of the law or comply with legal process
				served on Conform Collection AB or the site; (b) protect and
				defend the rights or property of Conform Collection AB; and, (c)
				act under exigent circumstances to protect the personal safety
				of users of Conform Collection AB, or the public.
				<br />
				<br />
				<b>Use of Cookies</b>
				<br />
				<br />
				The Conform Collection AB Web site use "cookies" to help you
				personalize your online experience. A cookie is a text file that
				is placed on your hard disk by a Web page server. Cookies cannot
				be used to run programs or deliver viruses to your computer.
				Cookies are uniquely assigned to you, and can only be read by a
				web server in the domain that issued the cookie to you.
				<br />
				<br />
				One of the primary purposes of cookies is to provide a
				convenience feature to save you time. The purpose of a cookie is
				to tell the Web server that you have returned to a specific
				page. For example, if you personalize Conform Collection AB
				pages, or register with Conform Collection AB site or services,
				a cookie helps Conform Collection AB to recall your specific
				information on subsequent visits. This simplifies the process of
				recording your personal information, such as billing addresses,
				shipping addresses, and so on. When you return to the same
				Conform Collection AB Web site, the information you previously
				provided can be retrieved, so you can easily use the Conform
				Collection AB features that you customized.
				<br />
				<br />
				You have the ability to accept or decline cookies. Most Web
				browsers automatically accept cookies, but you can usually
				modify your browser setting to decline cookies if you prefer. If
				you choose to decline cookies, you may not be able to fully
				experience the interactive features of the Conform Collection AB
				services or Web sites you visit.
				<br />
				<br />
				Security of your Personal Information
				<br />
				<br />
				Conform Collection AB secures your personal information from
				unauthorized access, use or disclosure. Conform Collection AB
				secures the personally identifiable information you provide on
				computer servers in a controlled, secure environment, protected
				from unauthorized access, use or disclosure. When personal
				information (such as a credit card number) is transmitted to
				other Web sites, it is protected through the use of encryption,
				such as the Secure Socket Layer (SSL) protocol.
				<br />
				<br />
				Changes to this Statement
				<br />
				<br />
				Conform Collection AB will occasionally update this Statement of
				Privacy to reflect company and customer feedback. Conform
				Collection AB encourages you to periodically review this
				Statement to be informed of how Conform Collection AB is
				protecting your information.
				<br />
				<br />
				Contact Information
				<br />
				<br /> Conform Collection AB welcomes your comments regarding
				this Statement of Privacy. If you believe that Conform
				Collection AB has not adhered to this Statement, please contact
				Conform Collection AB at host@change.me. We will use
				commercially reasonable efforts to promptly determine and remedy
				the problem.
			</p>
		</div>
	);
};

export default Privacy;
