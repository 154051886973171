import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ConformLogo from '../../assets/images/logo-conform.svg';

import './styles.scss';

const Logo = (props) => {
	const { size } = props;
	const classes = classNames({
		logo: true,
		[`logo-size--${size}`]: size,
	});
	return (
		<div className={classes}>
			<img className='logo__img' src={ConformLogo} alt='Conform' />
		</div>
	);
};

Logo.propTypes = {
	size: PropTypes.oneOf(['small', 'medium', 'normal', 'large']),
};

Logo.defaultProps = {
	size: 'normal',
};

export default Logo;
