import React from 'react';
import classNames from 'classnames';
import DetailThumbnail from '../DetailThumbnail';
import useData from '../../businessLogic/hooks/useData';
import Icon from '../Icon';
import { get } from 'lodash';

import './styles.scss';

const DetailThumbnailList = () => {
	const { data, SetShowDetailView } = useData();
	const classes = classNames({
		'detail-thumbnail-list': true,
	});

	return (
		<div className={classes}>
			<div
				className='detail-thumbnail-list__back-to-360'
				onClick={() => {
					SetShowDetailView(false);
				}}>
				<span>360°</span>
				<Icon iconName='360-degree' size='normal' />
			</div>
			{get(data, 'images', []).map((item, index) => {
				if (get(item, 'visualType') !== 'POI') return null;
				return (
					<DetailThumbnail
						thumbnailData={item}
						index={index}
						key={index}
					/>
				);
			})}
		</div>
	);
};

export default DetailThumbnailList;
