import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createS3ImagePath } from '../../helpers/images';
import useData from '../../businessLogic/hooks/useData';
import { get } from 'lodash';

import './styles.scss';

const DetailThumbnail = (props) => {
	const { thumbnailData, index } = props;
	const {
		swiperElement,
		SetActiveSlideFunc,
		showDetailView,
		SetShowDetailView,
	} = useData();

	const goToClickedSlide = (index) => {
		if (swiperElement && swiperElement.swiper) {
			swiperElement.swiper.slideTo(index);
			SetActiveSlideFunc(index);
			if (!showDetailView) SetShowDetailView(true);
		}
	};
	const classes = classNames({
		'detail-thumbnail': true,
	});

	return (
		<div className={classes}>
			<div
				className='detail-thumbnail__image-wrap'
				onClick={() => goToClickedSlide(index)}>
				<div
					className='detail-thumbnail__image-wrap__layer upholstery'
					style={{
						backgroundImage: `url('${createS3ImagePath(
							get(thumbnailData, ['upholstery', 'imageUrl']),
							'/'
						)}')`,
					}}
				/>
				<div
					className='detail-thumbnail__image-wrap__layer shell'
					style={{
						backgroundImage: `url('${createS3ImagePath(
							get(thumbnailData, ['shell', 'imageUrl']),
							'/'
						)}')`,
					}}
				/>
				<div
					className='detail-thumbnail__image-wrap__layer feet'
					style={{
						backgroundImage: `url('${createS3ImagePath(
							get(thumbnailData, ['feet', 'imageUrl']),
							'/'
						)}')`,
					}}
				/>
			</div>
		</div>
	);
};

DetailThumbnail.propTypes = {
	thumbnailData: PropTypes.object,
};

DetailThumbnail.defaultProps = {
	thumbnailData: {},
};

export default DetailThumbnail;
