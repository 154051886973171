import React from 'react';
import PropTypes from 'prop-types';
import Thumbnail from '../Thumbnail';
import { get } from 'lodash';

import './styles.scss';

const ThumbnailList = (props) => {
	const {
		selections,
		rounded,
		category,
		stateProperty,
		detail,
		type,
		optionFileInfo,
	} = props;

	const selectionsCleaned = selections.filter(function (item) {
		return get(item, 'visualState') !== 'disabled';
	});

	return (
		<ul className='thumbnail-list'>
			{selectionsCleaned.map((option, index) => {
				return (
					<Thumbnail
						key={index}
						optionIndex={index}
						size='small'
						shape={rounded ? 'rounded' : 'squared'}
						thumbnailData={option}
						type={type}
						detail={detail}
						labelBg={true}
						category={category}
						stateProperty={stateProperty}
						selections={selectionsCleaned}
						optionFileInfo={optionFileInfo}
					/>
				);
			})}
		</ul>
	);
};

ThumbnailList.propTypes = {
	thumbnailSrc: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	rounded: PropTypes.oneOf([true, false]),
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	labelBg: PropTypes.bool,
	type: PropTypes.string,
};

ThumbnailList.defaultProps = {
	rounded: true,
	size: 'normal',
	thumbnailSrc: '',
	name: '',
};

export default ThumbnailList;
