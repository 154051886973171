import React from 'react';
import PropTypes from 'prop-types';
import ThumbnailSlider from '../ThumbnailSlider';
import Icon from '../../../ui/Icon';
import classNames from 'classnames';
import useData from '../../hooks/useData';

import './styles.scss';

const DetailView = () => {
	const { SetShowDetailView, showDetailView } = useData();
	const classes = classNames({
		'detail-view': true,
		show: showDetailView,
	});
	return (
		<div className={classes}>
			<div
				className='detail-view__close-btn'
				onClick={() => {
					SetShowDetailView(false);
				}}>
				<div className='detail-view__close-btn__wrapper'>
					<div className='detail-view__close-btn__wrapper__icon'>
						<Icon iconName='close' size='normal' />
					</div>
					<span className='detail-view__close-btn__wrapper__text'>
						Back to 360° view
					</span>
				</div>
			</div>
			<ThumbnailSlider />
		</div>
	);
};

DetailView.propTypes = {
	show: PropTypes.bool,
};

DetailView.defaultProps = {
	show: false,
};

export default DetailView;
