import React from 'react';
import PropTypes from 'prop-types';
import Contacts from '../DialogContents/Contacts';
import Imprint from '../DialogContents/Imprint';
import LoadConfiguration from '../DialogContents/LoadConfiguration';
import Privacy from '../DialogContents/Privacy';
import ShareLink from '../DialogContents/ShareLink';
import Share from '../DialogContents/Share';
import NewProductInfo from '../DialogContents/NewProductInfo';
import SharePDF from '../DialogContents/SharePDF';
import SharePics from '../DialogContents/SharePics';
import ProductInformation from '../DialogContents/ProductInformation';

const DialogContentSwitcher = (props) => {
	const { child, closePopUp } = props;
	return (
		<>
			{
				{
					contacts: <Contacts />,
					imprint: <Imprint />,
					'qr-code': <LoadConfiguration closePopUp={closePopUp} />,
					'account-privacy': <Privacy />,
					'share-link': <ShareLink />,
					share: <Share />,
					info: <NewProductInfo />,
					'share-pdf': <SharePDF />,
					'share-pics': <SharePics />,
					'product-info': <ProductInformation />,
				}[child]
			}
		</>
	);
};

DialogContentSwitcher.propTypes = {
	child: PropTypes.string,
};

DialogContentSwitcher.defaultProps = {
	child: '',
};

export default DialogContentSwitcher;
