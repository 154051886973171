import React from 'react';
import PropTypes from 'prop-types';
import countryList from './country-list'
import Alert from '@material-ui/lab/Alert'

import './styles.scss';

const defaultFormValues = {
	title: '',
	firstName: '',
	lastName: '',
	country: 'Sweden',
	contactVia: 'email',
	emailAddress: '',
	phone: '',
	request: '',
	message: ''
}


const Contacts = (props) => {
	const [formValues, setFormValues] = React.useState(defaultFormValues);
	const [alerts, setAlerts] = React.useState([false, false]);
	const [fetchLoading, setFetchLoading] = React.useState(false);

	React.useEffect(() => {
		setFormValues({ ...defaultFormValues, request: window.location.pathname.slice(1) });
	}, [])
	
	const handleSubmit = (e) => {
		e.preventDefault();
		setFetchLoading(true);

		const emailBody = `
			Title: ${formValues.title}
			First Name: ${formValues.firstName}
			Last Name: ${formValues.lastName}
			Country: ${formValues.country}
			Contact via: ${formValues.contactVia}
			Email address: ${formValues.emailAddress}
			Phone: ${formValues.phone}
			Configuration: ${formValues.request}
			Message: ${formValues.message}
		`


		fetch('https://conformplayground.arcware.cloud/v1/mail/conform', 
			{
				method: 'POST', 
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({"subject": "New contact request from conform.arcware.cloud", "body": emailBody })
			})
			.then(() => {
				setFormValues(defaultFormValues);
				setAlerts([false, true]);
				setFetchLoading(false);
			})
			.catch(() => {
				setAlerts([true, false]);
				setFetchLoading(false);
			})
	}

	const onFieldChange = (event) => {
		setFormValues({ ...formValues, [event.currentTarget.name]: event.currentTarget.value })
	}

	return (
		<div className='contacts-content'>
			<form className='contacts-content__form' onSubmit={handleSubmit}>
				<div className='contacts-content__form__item radio-buttons'>
					<span className='contacts-content__form__item__title'>
						Title *
					</span>
					<div className='contacts-content__form__item__wrap'>
						<div className='contacts-content__form__item__wrap__radio'>
							<input
								type='radio'
								id='mr'
								name='title'
								value='mr'
								onChange={onFieldChange}
								required
								checked={formValues.title === 'mr'}
							/>
							<label htmlFor='mr'>Mr.</label>
						</div>
						<div className='contacts-content__form__item__wrap__radio'>
							<input
								type='radio'
								id='mrs'
								name='title'
								value='mrs'
								onChange={onFieldChange}
								required
								checked={formValues.title === 'mrs'}
							/>
							<label htmlFor='mrs'>Mrs.</label>
						</div>
					</div>
				</div>
				<div className='contacts-content__form__item'>
					<span className='contacts-content__form__item__title'>
						First name *
					</span>
					<div className='contacts-content__form__item__input'>
						<input type='text' name="firstName" required value={formValues.firstName} onChange={onFieldChange} />
					</div>
				</div>
				<div className='contacts-content__form__item'>
					<span className='contacts-content__form__item__title'>
						Last name *
					</span>
					<div className='contacts-content__form__item__input'>
						<input type='text' name="lastName" required value={formValues.lastName} onChange={onFieldChange}  />
					</div>
				</div>
				<div className='contacts-content__form__item'>
					<span className='contacts-content__form__item__title'>
						Country *
					</span>
					<div className='contacts-content__form__item__input' id="country-selector-wrapper">
						<select name="country" onChange={onFieldChange} value={formValues.country}>
							{countryList.map(val => (
								<option value={val} key={val}>{val}</option>
							))}
						</select>
					</div>
				</div>
				<div className='contacts-content__form__item radio-buttons'>
					<span className='contacts-content__form__item__title'>
						Contact via
					</span>
					<div className='contacts-content__form__item__wrap'>
						<div className='contacts-content__form__item__wrap__radio'>
							<input
								type='radio'
								id='email'
								name='contactVia'
								value='email'
								onChange={onFieldChange}
								checked={formValues.contactVia === 'email'}
							/>
							<label htmlFor='email'>e-mail</label>
						</div>
						<div className='contacts-content__form__item__wrap__radio'>
							<input
								type='radio'
								id='phone'
								name='contactVia'
								onChange={onFieldChange}
								value='phone'
								checked={formValues.contactVia === 'phone'}
							/>
							<label htmlFor='phone'>phone (call back)</label>
						</div>
					</div>
				</div>
				<div className='contacts-content__form__item'>
					<span className='contacts-content__form__item__title'>
						E-mail address {formValues.contactVia === 'email' && `*`}
					</span>
					<div className='contacts-content__form__item__input'>
						<input  required={formValues.contactVia === 'email'}
								pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}" 
								type="email" 
								name="emailAddress"
								value={formValues.emailAddress} onChange={onFieldChange} />
					</div>
				</div>
				<div className='contacts-content__form__item'>
					<span className='contacts-content__form__item__title'>
						Phone {formValues.contactVia === 'phone' && `*`}
					</span>
					<div className='contacts-content__form__item__input'>
						<input required={formValues.contactVia === 'phone'} type='tel' name="phone" value={formValues.phone} onChange={onFieldChange}  />
					</div>
				</div>
				<div className='contacts-content__form__item'>
					<span className='contacts-content__form__item__title'>
						Configuration *
					</span>
					<div className='contacts-content__form__item__input'>
						<input type='text' required name="request" value={formValues.request} onChange={onFieldChange}  />
					</div>
				</div>
				<div className='contacts-content__form__item message'>
					<span className='contacts-content__form__item__title'>
						Message *
					</span>
					<div className='contacts-content__form__item__wrap'>
						<textarea type='text' required name="message" value={formValues.message} onChange={onFieldChange}  />
					</div>
				</div>
				<button className='contacts-content__form__submit' disabled={fetchLoading}>send</button>
			</form>
			<div className='contacts-alerts'>
				{alerts[0] && <Alert variant="filled" severity="warning">
					Something went wrong
				</Alert>}
				{alerts[1] && <Alert variant="filled" severity="success">
					Your request has been sent! We will contact you shortly.
				</Alert>}
			</div>
		</div>
	);
};

Contacts.propTypes = {
	child: PropTypes.string,
};

Contacts.defaultProps = {
	child: '',
};

export default Contacts;
