import React, { useState } from 'react';
import Icon from '../Icon';
import PopUp from '../PopUp';
import DialogContentSwitcher from '../../businessLogic/components/DialogContentSwitcher';
import PropTypes from 'prop-types';

import './styles.scss';

const ShareButton = (props) => {
	const { icon, size, text } = props;
	const [showPopUp, setShowPopUp] = useState(false);

	function handleActivePopUp() {
		setShowPopUp(true);
	}

	function handleClosePopUp() {
		setShowPopUp(false);
	}
	return (
		<>
			<div
				className='share-button'
				title={text}
				onClick={() => {
					handleActivePopUp();
				}}>
				<Icon iconName={icon} size={size} />
				{text && <span className='share-button__text'>{text}</span>}
			</div>
			<PopUp
				closePopUp={() => handleClosePopUp()}
				show={showPopUp}
				title='Share'
				type='generic share-pop-up'>
				<DialogContentSwitcher child={'share'} />
			</PopUp>
		</>
	);
};

Icon.propTypes = {
	icon: PropTypes.string,
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	title: PropTypes.string,
};

Icon.defaultProps = {
	icon: 'menu',
	size: 'small',
	title: 'Share',
};

export default ShareButton;
