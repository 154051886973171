import Home from '../businessLogic/pages/Home';
import Configurator from '../businessLogic/pages/Configurator';
import PDFDetails from '../businessLogic/pages/PDFDetails';
import ProductNotFound from '../businessLogic/pages/ProductNotFound';

const routes = [
	{
		path: '/',
		component: Home,
		exact: true,
	},
	{
		path: '/:filter',
		component: Home,
		exact: true,
	},
	{
		path: '/product-not-found',
		component: ProductNotFound,
	},
	{
		path: '/pdf/:filter?/:code',
		component: PDFDetails,
		exact: true,
	},
	{
		path: '/:filter?/:code?',
		component: Configurator,
		exact: true,
	},
];

export default routes;
