import React, { useState, useMemo } from 'react';
import './App.scss';
import DataContext from './businessLogic/context/DataContext';
import Navigation from './globalRoutes/Navigation';
import { get } from 'lodash';
import deviceDetect from './helpers/deviceDetect';
import {
	getData,
	updateData,
	ramdomize,
} from './businessLogic/services/fetchData';

const App = () => {
	const isMobile = deviceDetect();
	const [dataLoaded, setDataLoaded] = useState(false);
	const [swiperElement, setSwiperElement] = useState([]);
	const [activeSlide, setActiveSlide] = useState(0);
	const [showDetailView, setShowDetailView] = useState(false);
	const [multiAccordionHeight, setMultiAccordionHeight] = useState(37);
	const [data, setData] = useState({});
	const [showConfigurator, setShowConfigurator] = useState(!isMobile);
	const SetHeightMultiAccordion = (mainHeight, itemHeight) => {
		setMultiAccordionHeight(mainHeight + itemHeight);
	};
	const [filter, setFilter] = useState('');

	const GetDataApp = async () => {
		getData().then((items) => {
			setData(items);
			setDataLoaded(true);
		});
	};

	const UpdateStateDataApp = (stateObject, page) => {
		updateData(stateObject).then((items) => {
			setData(items);
			setDataLoaded(true);
			switch (page) {
				case 'configurator':
					if (!get(items, 'error')) {
						window.history.pushState(
							{},
							null,
							`/${filter}/${get(items, 'productCode')}`
						);
					} else {
						window.history.pushState({}, null, 'product-not-found');
					}
					break;
				case 'home':
					if (filter) {
						if (!filter.includes('.'))
							window.history.pushState({}, null, `/${filter}`);
					}

					break;
				case 'pdf':
					if (!filter) {
						window.history.pushState(
							{},
							null,
							`/pdf/default/${get(items, 'productCode')}`
						);
					} else {
						window.history.pushState(
							{},
							null,
							`/pdf/${filter}/${get(items, 'productCode')}`
						);
					}

					break;
				default:
					if (!get(items, 'error')) {
						window.history.pushState({}, null, `/${filter}`);
					} else {
						window.history.pushState({}, null, 'product-not-found');
					}
					break;
			}
		});
	};

	const GetRandomize = async (objData, filter) => {
		ramdomize(objData, filter).then((items) => {
			setData(items);
			setDataLoaded(true);
			if (!get(items, 'error')) {
				window.history.pushState(
					{},
					null,
					`/${filter}/${get(items, 'productCode')}`
				);
			} else {
				window.history.pushState({}, null, 'product-not-found');
			}
		});
	};

	const SetShowDetailView = (value, index) => {
		setShowDetailView(value);
	};
	const SetActiveSlideFunc = (index) => {
		setActiveSlide(index);
	};

	const SliderRef = (element) => {
		setSwiperElement(element);
	};

	const HandleShowConfigurator = (value) => {
		setShowConfigurator(!value);
	};

	const UpdateFilter = (currentFilter) => {
		setFilter(currentFilter);
	};

	const useData = useMemo(
		() => ({
			data,
			dataLoaded,
			GetDataApp,
			UpdateStateDataApp,
			GetRandomize,
			SetActiveSlideFunc,
			SliderRef,
			swiperElement,
			activeSlide,
			showDetailView,
			SetShowDetailView,
			SetHeightMultiAccordion,
			multiAccordionHeight,
			showConfigurator,
			HandleShowConfigurator,
			UpdateFilter,
			filter,
		}), // eslint-disable-next-line react-hooks/exhaustive-deps
		[
			data,
			dataLoaded,
			activeSlide,
			swiperElement,
			showDetailView,
			multiAccordionHeight,
			showConfigurator,
			filter,
		]
	);

	return (
		<DataContext.Provider value={useData}>
			<Navigation />
		</DataContext.Provider>
	);
};

export default App;
