import React from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';

import './styles.scss';

const Icon = (props) => {
    const {iconName, size} = props;
    const classes = classNames({
        'icon-cmp': true,
        [`icon-${iconName}`]: iconName,
        [`${size}`] : size,
    });
    return(
        <i className={classes} />
    )
}

Icon.propTypes = {
    iconName: PropTypes.string,
    size: PropTypes.oneOf(['small', 'normal' , 'large']),
};

Icon.defaultProps = {
    iconName: 'menu',
    size: 'small',
};

export default Icon;