import React from 'react';
import PropTypes from 'prop-types';
import AccordionItem from '../../AccordionItem';
import MultiAccordion from '../MultiAccordion';
import SwitchBlock from '../../SwitchBlock';
import ThumbnailList from '../../ThumbnailList';
import useData from '../../../businessLogic/hooks/useData';
import { get } from 'lodash';
import './styles.scss';

const OptionsBlock = (props) => {
	const { options } = props;
	const { data } = useData();
	const { stateDisplayNames } = data;
	return (
		<div className='options-block'>
			<div className='options-block__list'>
				{options.map((option, index) => {
					if (get(option, 'visualState') === 'disabled') return null;
					return (
						<React.Fragment key={index}>
							{
								{
									backgrounds: null,
									product: (
										<AccordionItem
											data={option}
											uppercase={true}
											visualState={get(
												option,
												'visualState'
											)}
											currentOption={get(
												stateDisplayNames,
												'product'
											)}>
											<ThumbnailList
												detail={true}
												rounded={true}
												category={get(
													option,
													'displayName'
												)}
												stateProperty={get(
													option,
													'name'
												)}
												selections={get(
													option,
													'selections'
												)}
												type='configurator-option'
											/>
										</AccordionItem>
									),
									materialtype: (
										<MultiAccordion
											configData={option}
											visualState={get(
												option,
												'visualState'
											)}
											open={true}
											currentOption={get(
												stateDisplayNames,
												'materialtype'
											)}
										/>
									),
									shelltype: (
										<SwitchBlock
											visualState={get(
												option,
												'visualState'
											)}
											option={option}
											currentOption={get(
												stateDisplayNames,
												'shelltype'
											)}
										/>
									),
									wood: (
										<AccordionItem
											visualState={get(
												option,
												'visualState'
											)}
											data={option}
											currentOption={get(
												stateDisplayNames,
												'wood'
											)}>
											<ThumbnailList
												detail={true}
												category={get(
													option,
													'displayName'
												)}
												rounded={true}
												stateProperty={get(
													option,
													'name'
												)}
												selections={get(
													option,
													'selections'
												)}
												type='configurator-option'
											/>
										</AccordionItem>
									),
									footlegtype: (
										<AccordionItem
											visualState={get(
												option,
												'visualState'
											)}
											data={option}
											currentOption={get(
												stateDisplayNames,
												'footlegtype'
											)}>
											<ThumbnailList
												detail={true}
												category={get(
													option,
													'displayName'
												)}
												rounded={true}
												stateProperty={get(
													option,
													'name'
												)}
												selections={get(
													option,
													'selections'
												)}
												type='configurator-option'
											/>
										</AccordionItem>
									),
									metalcolor: (
										<AccordionItem
											visualState={get(
												option,
												'visualState'
											)}
											data={option}
											currentOption={get(
												stateDisplayNames,
												'metalcolor'
											)}>
											<ThumbnailList
												detail={true}
												category={get(
													option,
													'displayName'
												)}
												rounded={true}
												stateProperty={get(
													option,
													'name'
												)}
												selections={get(
													option,
													'selections'
												)}
												type='configurator-option'
											/>
										</AccordionItem>
									),
									soundsystem: (
										<SwitchBlock
											visualState={get(
												option,
												'visualState'
											)}
											option={option}
											currentOption={get(
												stateDisplayNames,
												'soundsystem'
											)}
										/>
									),
									tilting: (
										<SwitchBlock
											visualState={get(option, 'tilting')}
											option={option}
											currentOption={get(
												stateDisplayNames,
												'tilting'
											)}
										/>
									),
									tablecolor: (
										<AccordionItem
											visualState={get(
												option,
												'visualState'
											)}
											data={option}
											currentOption={get(
												stateDisplayNames,
												'tablecolor'
											)}>
											<ThumbnailList
												detail={true}
												category={get(
													option,
													'displayName'
												)}
												rounded={true}
												stateProperty={get(
													option,
													'name'
												)}
												selections={get(
													option,
													'selections'
												)}
												type='configurator-option'
											/>
										</AccordionItem>
									),
									tabletype: (
										<AccordionItem
											visualState={get(
												option,
												'visualState'
											)}
											data={option}
											currentOption={get(
												stateDisplayNames,
												'tabletype'
											)}>
											<ThumbnailList
												detail={true}
												category={get(
													option,
													'displayName'
												)}
												rounded={true}
												stateProperty={get(
													option,
													'name'
												)}
												selections={get(
													option,
													'selections'
												)}
												type='configurator-option'
											/>
										</AccordionItem>
									),
									with_stool: (
										<SwitchBlock
											visualState={get(
												option,
												'visualState'
											)}
											option={option}
											currentOption={get(
												stateDisplayNames,
												'with_stool'
											)}
										/>
									),
								}[get(option, 'name')]
							}
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

OptionsBlock.propTypes = {
	options: PropTypes.array,
	title: PropTypes.string,
};

OptionsBlock.defaultProps = {
	options: [],
	title: '',
};

export default OptionsBlock;
