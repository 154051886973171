import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useData from '../../businessLogic/hooks/useData';

import './styles.scss';

const SidebarPanel = (props) => {
	const { children, type } = props;
	const { showConfigurator } = useData();
	const classes = classNames({
		'sidebar-panel': true,
		[`type-${type}`]: true,
		open: showConfigurator,
	});
	return <div className={classes}>{children}</div>;
};

SidebarPanel.propTypes = {
	type: PropTypes.string,
	open: PropTypes.bool,
};

SidebarPanel.defaultProps = {
	open: true,
};

export default SidebarPanel;
