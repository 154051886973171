import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import './styles.scss';

const LoadConfiguration = (props) => {
	const { closePopUp } = props;
	const [showAlert, setShowAlert] = useState(false);
	let history = useHistory();
	const codeRef = useRef(null);
	const linkRef = useRef(null);
	const handleClick = () => {
		if (
			codeRef.current.value.length > 0 &&
			linkRef.current.value.length > 0
		) {
			setShowAlert(true);
			const alertTimeout = setTimeout(() => {
				setShowAlert(false);
			}, 3500);
			return () => clearTimeout(alertTimeout);
		} else {
			if (codeRef.current.value.length > 0) {
				history.push(`/${codeRef.current.value}`);
				closePopUp();
			}
			if (linkRef.current.value.length > 0) {
				const linkClean = linkRef.current.value.replace(
					window.location.origin,
					''
				);
				history.push(linkClean);
				closePopUp();
			}
		}
	};

	const classesAlert = classNames({
		'load-configuration__form__alert': true,
		show: showAlert,
	});

	return (
		<div className='load-configuration'>
			<form className='load-configuration__form'>
				<div className='load-configuration__form__item'>
					<label className='load-configuration__form__item__label'>
						ENTER ConfigCode
					</label>
					<input
						className='load-configuration__form__item__input'
						placeholder='Paste here your configuration code'
						ref={codeRef}
					/>
				</div>
				<div className='load-configuration__form__item'>
					<label className='load-configuration__form__item__label'>
						ENTER URL
					</label>
					<input
						className='load-configuration__form__item__input'
						placeholder='Paste here your share link'
						ref={linkRef}
					/>
				</div>
				<span className={classesAlert}>
					Send both options at the same time isn't possible. Please
					try again only using one field.
				</span>
			</form>
			<button
				className='load-configuration__form__submit'
				onClick={() => {
					handleClick();
				}}>
				Load
			</button>
		</div>
	);
};

export default LoadConfiguration;
