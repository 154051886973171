import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AccordionItem from '../../AccordionItem';
import ThumbnailList from '../../ThumbnailList';
import { get } from 'lodash';

import './styles.scss';

const TabContent = (props) => {
	const { selections, show, tabName, currentMaterialName, activeTab } = props;
	const classes = classNames({
		'tab-content': true,
		active: show,
	});

	return (
		<div className={classes}>
			{show && (
				<>
					{selections.map((selection, index) => {
						let materialDefault = '';
						const itemFound = selections.find(
							({ displayName }) =>
								displayName === currentMaterialName
						);
						if (tabName === activeTab) {
							if (activeTab === 'Fabric') {
								materialDefault = itemFound
									? currentMaterialName
									: 'Eros';
							}
							if (activeTab === 'Leather') {
								materialDefault = itemFound
									? currentMaterialName
									: 'Fantasy';
							}
						} else {
							materialDefault = currentMaterialName;
						}

						return (
							<AccordionItem
								data={selection}
								key={index}
								open={
									materialDefault ===
									get(selection, 'displayName')
								}
								inner={true}>
								<ThumbnailList
									rounded={true}
									selections={get(selection, 'selections')}
									stateProperty={get(selection, 'name')}
									type='configurator-option'
									detail={true}
									optionFileInfo={
										get(selection, 'productInfo') ||
										get(selection, 'optionInfo')
									}
									category={`${tabName} | ${get(
										selection,
										'displayName'
									)}`}
								/>
							</AccordionItem>
						);
					})}
				</>
			)}
		</div>
	);
};

TabContent.propTypes = {
	selections: PropTypes.array,
	show: PropTypes.bool,
	tabName: PropTypes.string,
};

TabContent.defaultProps = {
	selections: [],
	show: false,
	tabName: '',
};

export default TabContent;
