import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import OptionSlider from '../OptionSlider';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
}));

const ThumbnailDetailPopUp = (props) => {
	const classes = useStyles();
	const [showPopUp, setShowPopUp] = useState(false);
	const {
		thumbnailData,
		selections,
		category,
		optionIndex,
		stateProperty,
		optionFileInfo,
	} = props;
	return (
		<>
			<div
				className='btn-open-thumbnail-detail-pop-up'
				onClick={() => setShowPopUp(true)}>
				<Icon iconName='magnifying-glass' size='small' />
			</div>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className={`${classes.modal} thumbnail-detail-pop-up`}
				open={showPopUp}
				onClose={() => setShowPopUp(false)}
				closeAfterTransition={true}
				BackdropComponent={Backdrop}
				disableAutoFocus={true}
				BackdropProps={{
					timeout: 1000,
				}}>
				<Fade in={showPopUp}>
					<div className='thumbnail-detail-pop-up__content'>
						{showPopUp && (
							<>
								<div className='thumbnail-detail-pop-up__content__header'>
									{category && (
										<span className='thumbnail-detail-pop-up__content__header__title'>
											{category}
										</span>
									)}
									<div
										className='thumbnail-detail-pop-up__content__header__close-btn'
										onClick={() => setShowPopUp(false)}
									/>
								</div>
								<OptionSlider
									selections={selections}
									optionIndex={optionIndex}
									thumbnailData={thumbnailData}
									stateProperty={stateProperty}
									optionFileInfo={optionFileInfo}
								/>
							</>
						)}
					</div>
				</Fade>
			</Modal>
		</>
	);
};

ThumbnailDetailPopUp.propTypes = {
	thumbnailData: PropTypes.object,
	selections: PropTypes.array,
	category: PropTypes.string,
	optionIndex: PropTypes.number,
	stateProperty: PropTypes.string,
};

ThumbnailDetailPopUp.defaultProps = {
	thumbnailData: {},
	selections: [],
	optionIndex: 0,
};

export default ThumbnailDetailPopUp;
