import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import classNames from 'classnames';
import useData from '../../businessLogic/hooks/useData';
import { get } from 'lodash';

import './styles.scss';

const RandomizerButton = (props) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const { type, label, tooltip, title } = props;
	const { GetRandomize, data, filter } = useData();
	const currentState = get(data, 'state');
	const classes = classNames({
		'randomizer-btn': true,
		[`${type}`]: type,
	});

	const tooltipClasses = classNames({
		'randomizer-btn__tooltip': true,
		show: showTooltip,
	});

	useEffect(() => {
		const showTooltipTimeout = setTimeout(() => {
			setShowTooltip(true);
		}, 7500);
		return () => clearTimeout(showTooltipTimeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const hideTooltipTimeout = setTimeout(() => {
			setShowTooltip(false);
		}, 6500);
		return () => clearTimeout(hideTooltipTimeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showTooltip]);

	return (
		<div
			className={classes}
			onClick={() => {
				GetRandomize(get(currentState, 'backgrounds'), filter);
				setShowTooltip(false);
			}}>
			{label && (
				<span className='randomizer-btn__title'>
					Configuration RANDOMIZER
				</span>
			)}
			<div className='randomizer-btn__icon'>
				<Icon iconName='shuffle' size='small' />
			</div>

			{title && <span className='randomizer-btn__title'>{title}</span>}
			{tooltip && (
				<div className={tooltipClasses}>
					<span>Like to try the Randomizer?</span>
				</div>
			)}
		</div>
	);
};

RandomizerButton.propTypes = {
	type: PropTypes.string,
	label: PropTypes.bool,
	tooltip: PropTypes.bool,
};

RandomizerButton.defaultProps = {
	type: 'icon',
	label: false,
	tooltip: false,
};

export default RandomizerButton;
