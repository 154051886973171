import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import classNames from 'classnames';
import useData from '../../businessLogic/hooks/useData';

import './styles.scss';

const AccordionItem = (props) => {
	const [contentHeight, setContentHeight] = useState(37);
	const {
		data,
		children,
		open,
		inner,
		currentOption,
		uppercase,
		visualState,
	} = props;
	const { displayName } = data;

	const [openAccordion, setOpenAccordion] = useState(
		open && visualState !== 'inactive'
	);
	const classes = classNames({
		'accordion-item': true,
		inner: inner,
		main: !inner,
		open: openAccordion && visualState !== 'inactive',
		[`${visualState}`]: true,
	});

	const { multiAccordionHeight, SetHeightMultiAccordion } = useData();

	const currentValueClasses = classNames({
		'accordion-item__title-clickable__current-value': true,
		uppercase: uppercase,
	});
	const refContainer = useRef(null);
	useEffect(() => {
		if (openAccordion) {
			if (visualState === 'inactive') {
				setContentHeight(37);
				setOpenAccordion(false);
			}
			const accordionit = setTimeout(() => {
				setContentHeight(
					refContainer.current.getBoundingClientRect().height + 37
				);
			}, 800);
			return () => clearTimeout(accordionit);
		}
	}, [openAccordion, visualState]);

	const onChangeActiveThisAccordion = (value) => {
		setOpenAccordion(value);
		setContentHeight(
			refContainer.current.getBoundingClientRect().height + 37
		);
		if (inner) {
			if (value) {
				SetHeightMultiAccordion(
					multiAccordionHeight,
					refContainer.current.getBoundingClientRect().height
				);
			} else {
				SetHeightMultiAccordion(
					multiAccordionHeight,
					-refContainer.current.getBoundingClientRect().height
				);
			}
		}
	};
	return (
		<div
			className={classes}
			style={{
				height: openAccordion ? `${contentHeight}px` : `37px`,
			}}>
			<div
				className='accordion-item__title-clickable'
				onClick={() => {
					onChangeActiveThisAccordion(!openAccordion);
				}}>
				<h3 className='accordion-item__title-clickable__text'>
					{displayName}
				</h3>
				<span className={currentValueClasses}>{currentOption}</span>
				<div
					className={`accordion-item__title-clickable__icon ${
						inner ? 'inner-icon' : ''
					}`}>
					<Icon iconName='arrow-up' size='normal' />
				</div>
			</div>
			<div className='accordion-item__content' ref={refContainer}>
				{children}
			</div>
		</div>
	);
};

AccordionItem.propTypes = {
	data: PropTypes.object,
	open: PropTypes.bool,
};

AccordionItem.defaultProps = {
	data: {},
	open: false,
};

export default AccordionItem;
