import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { createS3ImagePath } from '../../helpers/images';

import './styles.scss';

const ImageExampleComposer = (props) => {
	const { images } = props;
	return (
		<div className='image-example-composer'>
			{images.map((layer, index) => {
				if (get(layer, 'visualType') !== 'CAROUSEL') return null;
				if (
					get(layer, 'visualType') === 'CAROUSEL' &&
					get(layer, 'imageList').length === 0
				)
					return null;
				return (
					<div
						className={`image-example-composer__layer ${get(
							layer,
							'name'
						)}`}
						style={{ zIndex: get(layer, 'zIndex') }}
						key={index}>
						<img
							className='image-example-composer__layer__image'
							src={createS3ImagePath(
								get(layer, ['imageList', '0', 'imageUrl']),
								'/'
							)}
							alt={get(layer, 'name')}
						/>
					</div>
				);
			})}
		</div>
	);
};

ImageExampleComposer.propTypes = {
	images: PropTypes.array,
};

ImageExampleComposer.defaultProps = {
	images: [],
};

export default ImageExampleComposer;
