import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { pageData } from '../../../data/data';
import { get } from 'lodash';
import PopUp from '../../PopUp';
import NavItem from '../NavItem';
import DialogContentSwitcher from '../../../businessLogic/components/DialogContentSwitcher';

import './styles.scss';

const Menu = (props) => {
	const { show } = props;
	const { menuOptions } = pageData;
	const classes = classNames({
		menu: true,
		open: show,
	});

	const [activePopUp, setActiveOption] = useState({});

	function handleActivePopUp(option, title) {
		setActiveOption({ show: true, option: option, title: title });
	}

	function handleClosePopUp() {
		setActiveOption({ show: false, option: '', title: '' });
	}

	return (
		<>
			<nav className={classes}>
				<ul className='menu__list'>
					{menuOptions.map((item, index) => {
						return (
							<NavItem
								key={index}
								icon={get(item, 'icon')}
								link={get(item, 'link')}
								name={get(item, 'name')}
								onClickHandle={handleActivePopUp}
								active={
									get(activePopUp, 'option') ===
									get(item, 'link')
								}
							/>
						);
					})}
				</ul>
			</nav>
			<PopUp
				closePopUp={() => handleClosePopUp()}
				show={get(activePopUp, 'show')}
				title={get(activePopUp, 'title')}
				type={`menu-pop-up ${get(activePopUp, 'option')}`}>
				<DialogContentSwitcher
					child={get(activePopUp, 'option')}
					closePopUp={() => handleClosePopUp()}
				/>
			</PopUp>
		</>
	);
};

Menu.propTypes = {
	show: PropTypes.bool,
};

Menu.defaultProps = {
	show: false,
};

export default Menu;
