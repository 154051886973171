import React from 'react';
import PropTypes from 'prop-types';
import ThumbnailList from '../ThumbnailList';
import useData from '../../businessLogic/hooks/useData';
import { get } from 'lodash';

import './styles.scss';

const ScenesBlock = () => {
	const { data } = useData();
	const backgrounds = get(data, 'configurator').find(
		(option) => option.name === 'backgrounds'
	);
	return (
		<div className='scenes-block'>
			<span className='scenes-block__title'>
				{get(backgrounds, 'displayName')}
			</span>
			<ThumbnailList
				category={get(backgrounds, 'displayName')}
				stateProperty={get(backgrounds, 'name')}
				selections={get(backgrounds, 'selections')}
				type='scene-option'
				rounded={false}
				detail={false}
			/>
		</div>
	);
};

ScenesBlock.propTypes = {
	options: PropTypes.array,
	title: PropTypes.string,
};

ScenesBlock.defaultProps = {
	options: [],
	title: '',
};

export default ScenesBlock;
