import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import InfoButton from '../../ui/InfoButton';
import useData from '../../businessLogic/hooks/useData';
import deviceDetect from '../../helpers/deviceDetect';

import './styles.scss';

const SidebarTitle = (props) => {
	const isMobile = deviceDetect();
	const [showTooltip, setShowTooltip] = useState(true);
	const { product, title, subtitle, iconPosition, type } = props;
	const { showConfigurator, HandleShowConfigurator } = useData();

	useEffect(() => {
		const titleTooltipTimeout = setTimeout(() => {
			setShowTooltip(false);
		}, 6000);
		return () => clearTimeout(titleTooltipTimeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const classes = classNames({
		'sidebar-title': true,
		[`type-${type}`]: true,
		open: showConfigurator,
	});

	const tooltipClasses = classNames({
		'sidebar-title__tooltip': true,
		show: showTooltip,
	});
	return (
		<div className={classes}>
			{isMobile && (
				<div className={tooltipClasses}>
					<span>Click here to configure</span>
				</div>
			)}
			<div
				className='sidebar-title__wrapper'
				onClick={() => {
					HandleShowConfigurator(showConfigurator);
					setShowTooltip(false);
				}}>
				{iconPosition === 'left' && (
					<span className='sidebar-title__wrapper__icon left'>
						<Icon iconName='arrow-up' size='normal' />
					</span>
				)}
				{title && (
					<span className='sidebar-title__wrapper__text'>
						{title}
					</span>
				)}
				{subtitle && (
					<span className='sidebar-title__wrapper__subtitle'>
						{subtitle}
					</span>
				)}
				{product && (
					<span className='sidebar-title__wrapper__product-name'>
						{product}
					</span>
				)}
				{iconPosition === 'right' && (
					<span className='sidebar-title__wrapper__icon right'>
						<Icon iconName='arrow-up' size='normal' />
					</span>
				)}
			</div>
			<InfoButton icon='info' size='small' title='Product Info' />
		</div>
	);
};

SidebarTitle.propTypes = {
	open: PropTypes.bool,
	product: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	iconPosition: PropTypes.string,
};

SidebarTitle.defaultProps = {
	open: false,
};

export default SidebarTitle;
