import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { map } from 'lodash';

import routes from './routes';
import CookieBanner from '../businessLogic/components/CookieBanner/index';

export default function Navigation() {
	return (
		<Router>
			<CookieBanner />
			<Switch>
				{map(routes, (route, index) => (
					<Route
						key={index}
						path={route.path}
						exact={route.exact}
						isPrivate={route.isPrivate}
						component={route.component}
					/>
				))}
			</Switch>
		</Router>
	);
}
