import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import Icon from '../../../../ui/Icon';
import ImageExampleComposer from '../../../../ui/ImageExampleComposer';
import { QRCode } from 'react-qr-svg';
import useData from '../../../hooks/useData';
import { get } from 'lodash';
import SmallLoader from '../../../../ui/SmallLoader';
import { createImagesListsForZip } from '../../../../helpers/images';
import { fetchTxtFile } from '../../../../data/data';

import './styles.scss';

const {
	REACT_APP_API_URL_BASE_URI,
	REACT_APP_API_URL_IMAGES_ZIP,
	REACT_APP_API_URL_GENERATEPDF,
} = process.env;
const Share = () => {
	const [textCopied, setTextCopied] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [generateFilesRequestObj, setGenerateFilesRequestObj] = useState({
		status: '',
		request: '',
	});
	const [arrayOptionsInfo, setArrayOptionsInfo] = useState([]);
	const { data, filter } = useData();
	const { images, stateDisplayNames } = data;
	const linkRef = useRef(null);

	React.useEffect(() => {
		data.configuratorOptions = data.configurator;
		fetchTxtFile(data).then((response) => setArrayOptionsInfo(response));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Function to call API endpoint to generate pdf
	const getGeneratePDF = (url, fileName) => {
		const newObj = {
			url: url,
			filename: fileName,
		};

		let xhr = new XMLHttpRequest();
		xhr.open('POST', `${REACT_APP_API_URL_GENERATEPDF}`, true);
		xhr.responseType = 'blob';
		xhr.onprogress = function () {
			setGenerateFilesRequestObj({
				status: 'requesting',
				request: 'pdf',
			});
		};

		xhr.onload = function () {
			if (this.status === 200) {
				let blob = this.response;
				let disposition = xhr.getResponseHeader('Content-Disposition');
				if (disposition && disposition.indexOf('attachment') !== -1) {
					let filenameRegex =
						/fileName[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					let matches = filenameRegex.exec(disposition);
					if (matches != null && matches[1])
						fileName = matches[1].replace(/['"]/g, '');
				}

				if (typeof window.navigator.msSaveBlob !== 'undefined') {
					// IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
					window.navigator.msSaveBlob(blob, fileName);
				} else {
					let URL = window.URL || window.webkitURL;
					let downloadUrl = URL.createObjectURL(blob);

					if (fileName) {
						// use HTML5 a[download] attribute to specify filename
						let a = document.createElement('a');
						// safari doesn't support this yet
						if (typeof a.download === 'undefined') {
							window.location.href = downloadUrl;
						} else {
							a.href = downloadUrl;
							a.download = fileName;
							document.body.appendChild(a);
							a.click();
							return 'done';
						}
					} else {
						window.location.href = downloadUrl;
					}

					setTimeout(function () {
						URL.revokeObjectURL(downloadUrl);
					}, 100); // cleanup
				}
			}
			return xhr.status;
		};
		xhr.setRequestHeader('Content-type', 'application/json');
		xhr.send(JSON.stringify(newObj));
		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && xhr.status === 200) {
				setGenerateFilesRequestObj({
					status: 'finished',
					request: 'pdf',
				});
			}
		};
	};

	// Function to call API endpoint to zip images
	const getZipImages = (imageLists, fileName) => {
		const newObj = {
			imagelists: imageLists,
			baseURI: `${REACT_APP_API_URL_BASE_URI}`,
			format: 'png',
			filename: fileName,
		};

		let xhr = new XMLHttpRequest();
		xhr.open('POST', `${REACT_APP_API_URL_IMAGES_ZIP}`, true);
		xhr.responseType = 'blob';
		xhr.onprogress = function () {
			setGenerateFilesRequestObj({
				status: 'requesting',
				request: 'zip',
			});
		};

		xhr.onload = function () {
			if (this.status === 200) {
				let blob = this.response;
				let disposition = xhr.getResponseHeader('Content-Disposition');
				if (disposition && disposition.indexOf('attachment') !== -1) {
					let filenameRegex =
						/fileName[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					let matches = filenameRegex.exec(disposition);
					if (matches != null && matches[1])
						fileName = matches[1].replace(/['"]/g, '');
				}

				if (typeof window.navigator.msSaveBlob !== 'undefined') {
					// IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
					window.navigator.msSaveBlob(blob, fileName);
				} else {
					let URL = window.URL || window.webkitURL;
					let downloadUrl = URL.createObjectURL(blob);

					if (fileName) {
						// use HTML5 a[download] attribute to specify filename
						let a = document.createElement('a');
						// safari doesn't support this yet
						if (typeof a.download === 'undefined') {
							window.location.href = downloadUrl;
						} else {
							a.href = downloadUrl;
							a.download = fileName;
							document.body.appendChild(a);
							a.click();
							return 'done';
						}
					} else {
						window.location.href = downloadUrl;
					}

					setTimeout(function () {
						URL.revokeObjectURL(downloadUrl);
					}, 100); // cleanup
				}
			}
			return xhr.status;
		};
		xhr.setRequestHeader('Content-type', 'application/json');

		xhr.send(JSON.stringify(newObj));

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && xhr.status === 200) {
				setGenerateFilesRequestObj({
					status: 'finished',
					request: 'zip',
				});
			}
		};
	};

	const copyToClipboard = (option, text) => {
		navigator.clipboard.writeText(text);
		document.execCommand('copy');
		setAlertText(text);
		setTextCopied(true);

		const alertTimeout = setTimeout(() => {
			setTextCopied(false);
		}, 2000);
		return () => clearTimeout(alertTimeout);
	};
	const classesAlert = classNames({
		share__alert: true,
		show: textCopied,
	});

	const shareUrl = `${window.location.origin}/${filter}/${get(
		data,
		'shareLink'
	)}`;
	const productNameFormatted = get(data, 'productCode').replace(/\./g, '_');
	const zipFileName = `image-set-configuration-${productNameFormatted}`;
	const pdfFileName = `configuration-${productNameFormatted}`;
	const pdfUrl = `pdf/${filter}/${get(data, 'shareLink')}`;
	const downloadImagesZipFile = () => {
		const imagesLists = createImagesListsForZip(get(data, 'images'));
		getZipImages(imagesLists, zipFileName);
	};

	return (
		<div className='share'>
			<SmallLoader
				show={
					get(generateFilesRequestObj, 'status') === 'opened' ||
					get(generateFilesRequestObj, 'status') === 'requesting'
				}
				text={`Generating .${get(
					generateFilesRequestObj,
					'request'
				)} file`}
				background={true}
				color='black'
			/>
			<div className='share__left'>
				<div className='share__left__chair'>
					<ImageExampleComposer images={images} />
				</div>
			</div>
			<div className='share__right'>
				<h1 className='share__left__product-title'>
					{get(stateDisplayNames, 'product')}
				</h1>
				<div>
					{arrayOptionsInfo.map((option, index) => {
						return (
							<p
								className={`product-info-details__info__text ${index}`}
								key={index}>
								{get(option, 'text')}
							</p>
						);
					})}
				</div>
				<div className='share__right-qr_block'>
					<ul className='product-info-details__current-options-list'>
						{get(stateDisplayNames, 'materialtype', '').length >
							0 && (
							<li className='product-info-details__current-options-list__item'>
								<label>
									{get(stateDisplayNames, 'materialtype')}:
								</label>
								<span>
									{get(stateDisplayNames, 'materialtype') ===
										'Leather' &&
										get(stateDisplayNames, 'leather', '')
											.length > 0 &&
										get(stateDisplayNames, 'leather') +
											' ' +
											get(
												stateDisplayNames,
												get(
													stateDisplayNames,
													'leather'
												)?.toLowerCase()
											)}
									{get(stateDisplayNames, 'materialtype') ===
										'Fabric' &&
										get(stateDisplayNames, 'fabric', '')
											.length > 0 &&
										get(stateDisplayNames, 'fabric') +
											' ' +
											get(
												stateDisplayNames,
												get(
													stateDisplayNames,
													'fabric'
												)?.toLowerCase()
											)}
								</span>
							</li>
						)}
						{get(stateDisplayNames, 'shelltype', '').length > 0 && (
							<li className='product-info-details__current-options-list__item'>
								<label>Shell:</label>
								<span>
									{get(stateDisplayNames, 'shelltype')}
								</span>
							</li>
						)}
						{get(stateDisplayNames, 'wood', '').length > 0 && (
							<li className='product-info-details__current-options-list__item'>
								<label>Wood style:</label>
								<span>{get(stateDisplayNames, 'wood')}</span>
							</li>
						)}
						{get(stateDisplayNames, 'footlegtype', '').length >
							0 && (
							<li className='product-info-details__current-options-list__item'>
								<label>Foot option:</label>
								<span>
									{get(stateDisplayNames, 'footlegtype')}
								</span>
							</li>
						)}
						{get(stateDisplayNames, 'tilting', '').length > 0 && (
							<li className='product-info-details__current-options-list__item'>
								<label>Base type:</label>
								<span>{get(stateDisplayNames, 'tilting')}</span>
							</li>
						)}
					</ul>
					<div>
						<div className='share__right__share-options__qr-code'>
							<div className='share__right__share-options__qr-code__code'>
								<div className='share__right__share-options__qr-code__code-left'>
									<QRCode
										bgColor='transparent'
										fgColor='#000000'
										level='Q'
										value={shareUrl}
									/>
								</div>
								<div className='share__right__share-options__label'>
									Configuration QR code
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='share__right-resellers'>
					<li className='product-info-details__current-options-list__item'>
						<label>Global resellers:</label>
						<span>
							<a
								href='https://conform.se/en_us/resellers'
								target='_blank'
								rel='noopener noreferrer'>
								https://conform.se/en_us/resellers
							</a>
						</span>
					</li>
					<li className='product-info-details__current-options-list__item'>
						<label>Swedish resellers (map):</label>
						<span>
							<a
								href='https://conform.se/aterforsaljare'
								target='_blank'
								rel='noopener noreferrer'>
								https://conform.se/aterforsaljare
							</a>
						</span>
					</li>
				</div>
				<div className='share__right-download'>
					<li className='product-info-details__current-options-list__item'>
						<span>
							<b>Download</b> a picture set or a PDF with your
							configuration:{' '}
						</span>
						<span>
							<div className='share__left__download-options'>
								<div
									className='share__left__download-options__pdf'
									title='Download as PDF'
									onClick={() => {
										getGeneratePDF(pdfUrl, pdfFileName);
										setGenerateFilesRequestObj({
											status: 'opened',
											request: 'pdf',
										});
									}}>
									<Icon iconName='pdf' size='large' />
								</div>
								<div
									className='share__left__download-options__pictures'
									title='Download set of pictures'
									onClick={() => {
										downloadImagesZipFile();
										setGenerateFilesRequestObj({
											status: 'opened',
											request: 'zip',
										});
									}}>
									<Icon iconName='camera' size='large' />
								</div>
							</div>
						</span>
					</li>
					<li className='product-info-details__current-options-list__item share__right-links'>
						<label>Direct link:</label>
						<span className='share__right-direct-link'>
							<span
								ref={linkRef}
								className='share__right__share-options__short-link__textarea'>
								{`${window.location.origin}/${filter}/${get(
									data,
									'shareLink'
								)}`}
							</span>
							<div
								className='share__right__share-options__label__copy'
								title='Copy'
								onClick={() => {
									copyToClipboard(
										'link',
										`${window.location.origin}/${get(
											data,
											'shareLink'
										)}`
									);
								}}>
								<Icon iconName='copy' size='small' />
							</div>
						</span>
					</li>
				</div>
				{/* Uncomment when we have how to send emails */}
				{/* <div className='share__right__form'>
					<form className='share__right__form__item'>
						<div className='share__right__form__item__label'>
							<label>Enter e-email</label>
							<p>
								(enter multiple addresses, separated with ';' )
							</p>
						</div>
						<input
							className='share__right__form__item__input'
							placeholder='Enter here an email adress'
						/>
						<div className='share__right__form__item__btn-send'>
							<span>Send</span>
						</div>
					</form>
				</div> */}
			</div>
			<span
				className={
					classesAlert
				}>{`${alertText} copied to clipboard`}</span>
		</div>
	);
};

export default Share;
