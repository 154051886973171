import React, { useEffect } from 'react';
import Logo from '../../../ui/Logo';
import Catalogue from '../../components/Catalogue';
import Header from '../../../ui/MainHeader/Header';
import useData from '../../hooks/useData';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import './styles.scss';

const Home = () => {
	const history = useHistory();
	const { UpdateStateDataApp, data, dataLoaded, UpdateFilter } = useData();
	const { filter } = useParams();

	const updateData = async (newFilter) => {
		await UpdateStateDataApp({ filter: newFilter }, 'home');
		UpdateFilter(newFilter);
	};

	const redirectDotFilters = () => {
		if (filter && filter.includes('.')) history.push(`/default/${filter}`);
	};

	redirectDotFilters();
	useEffect(() => {
		if (filter && !filter.includes('.')) {
			updateData(filter);
		} else {
			updateData('default');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataLoaded]);

	const { configurator } = data;
	const productFound =
		configurator && configurator.find(({ name }) => name === 'product');
	const products = get(productFound, 'selections');

	return (
		<main className='home'>
			<Header current='home-page' />
			<div className='home__wrap-catalogue'>
				{dataLoaded && (
					<Catalogue
						products={products}
						show={dataLoaded}
						filter={filter}
					/>
				)}
			</div>
			<div className='home__wrap-logo'>
				<Logo size='normal' />
			</div>
		</main>
	);
};

export default Home;
