import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const MenuAnimateButton = (props) => {
	const { active, onHandleClick } = props;
	const classes = classNames({
		'menu-animate-button': true,
		active: active,
	});
	return (
		<div className={classes} onClick={() => onHandleClick(active)}>
			<div className='menu-animate-button__bar-wrapper'>
				<span className='menu-animate-button__bar-wrapper__bar one' />
				<span className='menu-animate-button__bar-wrapper__bar two' />
				<span className='menu-animate-button__bar-wrapper__bar three' />
			</div>
		</div>
	);
};

MenuAnimateButton.propTypes = {
	active: PropTypes.bool,
};

MenuAnimateButton.defaultProps = {
	active: false,
};

export default MenuAnimateButton;
