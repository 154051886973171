const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrlRandomize = `${process.env.REACT_APP_API_URL_RANDOMIZE}`;

export async function getData() {
	const response = await fetch(apiUrl, {
		method: 'POST',
	}).then((data) => data.json());
	return response;
}

export async function updateData(stateObject) {
	const response = await fetch(apiUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(stateObject),
	}).then((data) => data.json());
	return response;
}

export async function ramdomize(currentBackground, filter) {
	const newObjRequest = { state: { backgrounds: currentBackground }, filter };
	const response = await fetch(apiUrlRandomize, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(newObjRequest),
	}).then((data) => data.json());
	return response;
}
