import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import ImageExampleComposer from '../../../ui/ImageExampleComposer';
import ProductInfoDetails from '../../../ui/ProductInfoDetails';
import Logo from '../../../ui/Logo';
import { QRCode } from 'react-qr-svg';
import useData from '../../hooks/useData';
import { get } from 'lodash';
import './styles.scss';

const PDFDetails = () => {
	const { filter, code } = useParams();
	const { UpdateStateDataApp, data, dataLoaded, UpdateFilter } = useData();
	const newStateWithCode = { productCode: code, filter: filter };
	const { images, stateDisplayNames, state, configurator } = data;

	const updateData = async (state, newSelection) => {
		if (!newSelection) {
			await UpdateStateDataApp(state, 'pdf');
		} else {
			await UpdateStateDataApp({ state, newSelection }, 'pdf');
		}
	};

	useEffect(() => {
		updateData(newStateWithCode);
		UpdateFilter(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataLoaded, code]);

	if (get(data, 'error')) return <Redirect to='/product-not-found' />;

	const shareUrl = `${window.location.origin}/${
		!filter ? 'default`' : filter
	}/${get(data, 'shareLink')}`;

	return (
		<main className='pdf-details'>
			<div className='pdf-details__logo'>
				<Logo size='medium' />
			</div>
			<div className='pdf-details__content'>
				<div className='pdf-details__content__top'>
					<div className='pdf-details__content__top__chair'>
						<ImageExampleComposer images={images} />
					</div>
				</div>
				<div className='pdf-details__content__bottom'>
					<ProductInfoDetails
						stateDisplayNames={stateDisplayNames}
						type='pdf'
						configuratorOptions={configurator}
						state={state}
					/>
				</div>
			</div>
			<div className='pdf-details__content__footer'>
				<div className='pdf-details__content__footer-copyright'>
					© Conform Collection AB • www.conform.se
				</div>
				<div className='pdf-details__content__qr-code'>
					<div className='pdf-details__content__footer__qr-texts'>
						<div className='pdf-details__content__footer__qr-texts-header'>
							online configurator
						</div>
						<div>
							Scan the QR code with your phone to open the online
							configurator and see the chair on this page.
						</div>
					</div>
					<div className='pdf-details__content__qr-code__code'>
						<QRCode
							bgColor='transparent'
							fgColor='#000000'
							level='Q'
							value={shareUrl}
						/>
					</div>
				</div>
			</div>
		</main>
	);
};

export default PDFDetails;
