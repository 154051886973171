import React, { useRef, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import classNames from 'classnames';
import Icon from '../../../ui/Icon';
import { get } from 'lodash';
import useData from '../../hooks/useData';
// import ThumbnailsBlock from '../ThumbnailsBlock';

import { createS3ImagePath } from '../../../helpers/images.js';

import 'swiper/swiper.scss';
import './styles.scss';

const ThumbnailSlider = () => {
	const gallerySwiperRef = useRef(null);
	const gallerySwiperParams = {
		spaceBetween: 0,
		slidesPerView: 1,
		containerClass: 'thumbnail-slider__main-swiper',
		speed: 1250,
	};

	const { data, SliderRef, activeSlide, SetActiveSlideFunc } = useData();
	const { images } = data;
	const imagesPOIArray = [];
	for (let image of images) {
		if (get(image, 'visualType') === 'POI') imagesPOIArray.push(image);
	}

	useEffect(() => {
		if (gallerySwiperRef.current && gallerySwiperRef.current.swiper) {
			SliderRef(gallerySwiperRef.current);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gallerySwiperRef.current, activeSlide]);

	const goNext = () => {
		if (gallerySwiperRef.current && gallerySwiperRef.current.swiper) {
			gallerySwiperRef.current.swiper.slideNext();
			SetActiveSlideFunc(gallerySwiperRef.current.swiper.activeIndex);
		}
	};
	const goPrev = () => {
		if (gallerySwiperRef.current && gallerySwiperRef.current.swiper) {
			gallerySwiperRef.current.swiper.slidePrev();
			SetActiveSlideFunc(gallerySwiperRef.current.swiper.activeIndex);
		}
	};

	const classesPrev = classNames({
		'thumbnail-slider__prev-btn': true,
		disable: activeSlide === 0,
	});

	const classesNext = classNames({
		'thumbnail-slider__next-btn': true,
		disable: activeSlide === imagesPOIArray.length - 1,
	});

	return (
		<div className='thumbnail-slider'>
			<Swiper {...gallerySwiperParams} ref={gallerySwiperRef}>
				{imagesPOIArray.map((item, index) => {
					return (
						<div
							className='thumbnail-slider__main-swiper__slide'
							key={index}>
							<div
								className='thumbnail-slider__main-swiper__slide__layer upholstery'
								style={{
									zIndex: get(
										item,
										['upholstery', 'zIndex'],
										3
									),
								}}>
								<img
									className='thumbnail-slider__main-swiper__slide__layer__image'
									src={createS3ImagePath(
										get(item, ['upholstery', 'imageUrl']),
										'/'
									)}
									alt='upholstery'
								/>
							</div>
							<div
								className='thumbnail-slider__main-swiper__slide__layer shell'
								style={{
									zIndex: get(item, ['shell', 'zIndex'], 2),
								}}>
								<img
									className='thumbnail-slider__main-swiper__slide__layer__image'
									src={createS3ImagePath(
										get(item, ['shell', 'imageUrl']),
										'/'
									)}
									alt='upholstery'
								/>
							</div>
							<div
								className='thumbnail-slider__main-swiper__slide__layer feet'
								style={{
									zIndex: get(item, ['feet', 'zIndex'], 1),
								}}>
								<img
									className='thumbnail-slider__main-swiper__slide__layer__image'
									src={createS3ImagePath(
										get(item, ['feet', 'imageUrl']),
										'/'
									)}
									alt='upholstery'
								/>
							</div>
						</div>
					);
				})}
			</Swiper>
			<button className={classesPrev} onClick={goPrev}>
				<Icon iconName='arrow-up' size='large' />
			</button>
			<button className={classesNext} onClick={goNext}>
				<Icon iconName='arrow-up' size='large' />
			</button>
			{/* <ThumbnailsBlock
				thumbnails={items}
				onChangeSlide={goToClickedSlide}
				activeIndex={activeSlide}
			/> */}
		</div>
	);
};

export default ThumbnailSlider;
