import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
}));

const PopUp = (props) => {
	const classes = useStyles();
	const { show, closePopUp, type, title, width } = props;
	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			className={`${classes.modal} ${type} pop-up-portal`}
			open={show}
			onClose={closePopUp}
			closeAfterTransition={true}
			BackdropComponent={Backdrop}
			disableAutoFocus={true}
			BackdropProps={{
				timeout: 1000,
			}}>
			<Fade in={show}>
				<div className='pop-up' style={{ width: width }}>
					{show && (
						<>
							<div
								className='pop-up__header'
								onClick={props.closePopUp}>
								{title && (
									<h3 className='pop-up__header__title'>
										{title}
									</h3>
								)}
								<div
									className='pop-up__header__close-btn'
									onClick={props.closePopUp}
								/>
							</div>
							<div className='pop-up__content'>
								{props.children}
							</div>
						</>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

PopUp.propTypes = {
	show: PropTypes.bool,
	type: PropTypes.string,
	closePopUp: PropTypes.func,
	title: PropTypes.string,
	width: PropTypes.string,
};

PopUp.defaultProps = {
	show: false,
};

export default PopUp;
