import React from 'react';
import ImageExampleComposer from '../../../../ui/ImageExampleComposer';
import ProductInfoDetails from '../../../../ui/ProductInfoDetails';
import useData from '../../../hooks/useData';

import './styles.scss';

const NewProductInfo = () => {
	const { data } = useData();
	const { images, stateDisplayNames, configurator, state } = data;
	
	return (
		<div className='new-product-info'>
			<div className='new-product-info__chair'>
				<ImageExampleComposer images={images} />
			</div>
			<ProductInfoDetails
				stateDisplayNames={stateDisplayNames}
				type='pop-up-detail'
				configuratorOptions={configurator}
				state={state}
			/>
		</div>
	);
};

export default NewProductInfo;
