import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import TabsBlock from '../TabsBlock';
import classNames from 'classnames';
import useData from '../../../businessLogic/hooks/useData';
import { get } from 'lodash';

import './styles.scss';

const MultiAccordion = (props) => {
	const { configData, open, inner, currentOption, uppercase, visualState } =
		props;
	const { displayName } = configData;
	const { multiAccordionHeight, SetHeightMultiAccordion, data } = useData();
	const currentTabName = get(data, ['stateDisplayNames', 'materialtype']);
	const currentMaterialType = get(data, ['state', 'materialtype']);
	const currentMaterialState = get(data, ['state', currentMaterialType]);
	const currentMaterialName = get(data, [
		'stateDisplayNames',
		currentMaterialType,
	]);
	const currentOptionName = get(data, [
		'stateDisplayNames',
		currentMaterialState,
	]);

	const [openAccordion, setOpenAccordion] = useState(open);
	const classes = classNames({
		'multi-accordion-item': true,
		open: openAccordion,
	});

	const currentValueClasses = classNames({
		'multi-accordion-item__title-clickable__current-value': true,
		uppercase: uppercase,
		[`${visualState}`]: true,
	});
	const refContainer = useRef(null);
	useEffect(() => {
		if (openAccordion) {
			const accordionMain = setTimeout(() => {
				SetHeightMultiAccordion(
					0,
					refContainer.current.getBoundingClientRect().height + 37
				);
			}, 1800);
			return () => clearTimeout(accordionMain);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openAccordion]);

	const onChangeActiveThisAccordion = (value) => {
		setOpenAccordion(value);
		if (value) {
			SetHeightMultiAccordion(
				multiAccordionHeight,
				refContainer.current.getBoundingClientRect().height
			);
		} else {
			SetHeightMultiAccordion(
				multiAccordionHeight,
				-refContainer.current.getBoundingClientRect().height
			);
		}
	};
	return (
		<div
			className={classes}
			style={{
				height: openAccordion ? `${multiAccordionHeight}px` : `37px`,
			}}>
			<div
				className='multi-accordion-item__title-clickable'
				onClick={() => {
					onChangeActiveThisAccordion(!openAccordion);
				}}>
				<h3 className='multi-accordion-item__title-clickable__text'>
					{displayName}
				</h3>
				<span className={currentValueClasses}>
					{`${currentOption} `}
					{` |  ${currentMaterialName} `}
					{` |  ${currentOptionName}`}
				</span>
				<div
					className={`multi-accordion-item__title-clickable__icon ${
						inner ? 'inner-icon' : ''
					}`}>
					<Icon iconName='arrow-up' size='normal' />
				</div>
			</div>
			<div
				className='multi-accordion-item__tab-content'
				ref={refContainer}>
				<TabsBlock
					accordionIsOpen={openAccordion}
					category={get(configData, 'displayName')}
					selections={get(configData, 'selections')}
					currentTabName={currentTabName}
					currentMaterialName={currentMaterialName}
				/>
			</div>
		</div>
	);
};

MultiAccordion.propTypes = {
	data: PropTypes.object,
	open: PropTypes.bool,
};

MultiAccordion.defaultProps = {
	data: {},
	open: false,
};

export default MultiAccordion;
