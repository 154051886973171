import { get } from 'lodash';

export const createS3ImagePath = (imageUrl, separator) => {
	return `${process.env.REACT_APP_API_BASE_URL}${
		separator ? separator : ''
	}${imageUrl}`;
};

export const createImagesList = (path, fileType, length) => {
	let list = [];
	for (let i = 0; i < length; i++) {
		let image = `${path + (i + 1)}.${fileType}`;
		list.push(image);
	}
	return list;
};

const extractId = (imageUrl) => {
	let id = imageUrl;
	id = id.replace('images/renders/', '').replace('.webp', '');
	return id;
};

export const createImagesListsForZip = (images) => {
	let imagesLists = [];

	// Filtering and extracting 3D image of the chair
	let carouselItems = images.filter(
		({ visualType }) => visualType === 'CAROUSEL'
	);
	carouselItems = carouselItems.sort((a, b) => a.zIndex - b.zIndex);
	let carouselList = [];
	for (let i = 0; i < carouselItems.length; i++) {
		let imageLayer = get(carouselItems, [i, 'imageList', '0', '_id']);
		if (get(carouselItems, [i, 'imageList']).length > 0)
			carouselList.push(imageLayer);
	}

	imagesLists.push(carouselList);

	// Filtering and extracting POI images
	let poiItems = images.filter(({ visualType }) => visualType === 'POI');
	poiItems = poiItems.sort((a, b) => a.zIndex - b.zIndex);
	for (let j = 0; j < poiItems.length; j++) {
		const itemArrayLayers = [];
		if (get(poiItems, [j, 'feet']))
			itemArrayLayers.push(
				extractId(get(poiItems, [j, 'feet', 'imageUrl']))
			);
		if (get(poiItems, [j, 'shell']))
			itemArrayLayers.push(
				extractId(get(poiItems, [j, 'shell', 'imageUrl']))
			);
		if (get(poiItems, [j, 'upholstery']))
			itemArrayLayers.push(
				extractId(get(poiItems, [j, 'upholstery', 'imageUrl']))
			);

		imagesLists.push(itemArrayLayers);
	}

	return imagesLists;
};
