// import React, { PureComponent } from 'react';
import React, { useRef, useState, useEffect } from 'react';
import { useDelta } from 'react-delta';
import SmallLoader from '../../../ui/SmallLoader';
import './styles.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, isEqual } from 'lodash';
import { createS3ImagePath } from '../../../helpers/images.js';
import useData from '../../hooks/useData';

import image360BlackNew from '../../../assets/images/360-black.svg';

// higher values make mouse less sensitive
const pixelsPerDegree = 1.7;

const React360MultiLayer = (props) => {
	const [dragging, setDragging] = useState(false);
	const [imageIndex, setImageIndex] = useState(0);
	const [dragStart, setDragStart] = useState(0);
	const [dragStartIndex, setDragStartIndex] = useState(0);
	const [imagesLoaded, setImagesLoaded] = useState(0);
	const [numberOfImagesToLoad, setNumberOfImagesToLoad] = useState(0);
	const { showConfigurator, HandleShowConfigurator } = useData();
	const [imagesOneLayerNumber, setImagesOneLayerNumber] = useState(36);
	const [imageWidth, setImageWidth] = useState(0);

	const myRef = useRef(null);
	const myImageRef = useRef(null);
	const delta = useDelta(props);

	const countImagesToLoad = () => {
		let imagesToLoad = 0;
		props.layersList.forEach((layer) => {
			if (
				get(layer, 'visualType') === 'CAROUSEL' &&
				get(layer, 'imageList').length > 0
			) {
				imagesToLoad = imagesToLoad + get(layer, 'imageList').length;
			}
		});
		return imagesToLoad;
	};

	const updateImagesToLoad = (prevProps, currentProps) => {
		setImagesLoaded(0);
		setNumberOfImagesToLoad(0);
		let imagesCounter = 0;
		for (let layer of currentProps.layersList) {
			if (get(layer, 'visualType') === 'CAROUSEL') {
				const itemFound = get(prevProps, 'layersList').find(
					({ name }) => name === get(layer, 'name')
				);
				if (itemFound) {
					if (
						!isEqual(
							get(itemFound, 'imageList'),
							get(layer, 'imageList')
						)
					) {
						imagesCounter =
							imagesCounter + get(layer, 'imageList').length;
					}
				}
			}
		}
		return imagesCounter;
	};

	const checkNumberOfImagesOneLayer = () => {
		const { layersList } = props;
		const layerFound = layersList.find(
			({ visualType, name }) =>
				visualType === 'CAROUSEL' && name !== 'backplate'
		);
		const imagesOneLayer = get(layerFound, 'imageList').length;
		return imagesOneLayer;
	};

	useEffect(() => {
		const react360ref = myRef;
		// Instead componentDidMount
		react360ref.current.addEventListener(
			'mousemove',
			handleMouseMove,
			false
		);
		react360ref.current.addEventListener(
			'touchstart',
			handleTouchDown,
			false
		);
		react360ref.current.addEventListener(
			'touchmove',
			handleTouchMove,
			false
		);
		react360ref.current.addEventListener('mouseup', handleMouseUp, false);
		react360ref.current.addEventListener('touchend', handleMouseUp, false);
		react360ref.current.addEventListener('wheel', handleMouseWheel, false);
		return () => {
			// Instead ComponentWillUnmount
			react360ref.current.removeEventListener(
				'mousemove',
				handleMouseMove,
				false
			);
			react360ref.current.removeEventListener(
				'touchstart',
				handleTouchDown,
				false
			);
			react360ref.current.removeEventListener(
				'touchmove',
				handleTouchMove,
				false
			);
			react360ref.current.removeEventListener(
				'mouseup',
				handleMouseUp,
				false
			);
			react360ref.current.removeEventListener(
				'touchend',
				handleMouseUp,
				false
			);
			react360ref.current.removeEventListener(
				'wheel',
				handleMouseWheel,
				false
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dragging, props.zoom, dragStartIndex, imageIndex]);

	useEffect(() => {
		setNumberOfImagesToLoad(countImagesToLoad());
		setImagesOneLayerNumber(checkNumberOfImagesOneLayer());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (delta && delta.prev) {
			if (
				!isEqual(
					get(props, 'layersList'),
					get(delta, ['prev', 'layersList'])
				)
			) {
				setNumberOfImagesToLoad(updateImagesToLoad(delta.prev, props));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.layersList]);

	const handleMouseDown = (e) => {
		e.persist();
		setDragging(true);
		setDragStart(e.screenX);
		setDragStartIndex(imageIndex);
		const panel = setTimeout(() => {
			HandleShowConfigurator(showConfigurator);
		}, 150);
		return () => clearTimeout(panel);
	};

	const handleTouchDown = (e) => {
		setDragging(true);
		setDragStart(e.touches[0].clientX);
		setDragStartIndex(imageIndex);
		const panelMobile = setTimeout(() => {
			HandleShowConfigurator(showConfigurator);
		}, 150);
		return () => clearTimeout(panelMobile);
	};

	const handleMouseUp = () => {
		setDragging(false);
	};

	const handleMouseWheel = (e) => {
		const { zoom, onChangeZoom } = props;
		let newScaleValue = 0;
		if (e.wheelDeltaY > 0 && get(zoom, 'zoomValue') < 100) {
			newScaleValue = Math.round(get(zoom, 'zoomScaleValue') * 100 + 10);
			onChangeZoom(get(zoom, 'zoomValue') + 10, newScaleValue / 100);
		}
		if (e.wheelDeltaY < 0 && get(zoom, 'zoomValue') > 0) {
			newScaleValue = Math.round(get(zoom, 'zoomScaleValue') * 100 - 10);
			onChangeZoom(get(zoom, 'zoomValue') - 10, newScaleValue / 100);
		}
	};

	const handleMouseMove = (e) => {
		if (dragging) {
			HandleShowConfigurator(!showConfigurator);
			updateImageIndex(e.screenX);
		}
	};
	const handleTouchMove = (e) => {
		if (dragging) {
			HandleShowConfigurator(!showConfigurator);
			updateImageIndex(e.touches[0].clientX);
		}
	};
	const preventDragHandler = (e) => {
		e.preventDefault();
	};

	const updateImageIndex = (currentPosition) => {
		const pixelsPerImage = pixelsPerDegree * (360 / imagesOneLayerNumber);
		// pixels moved
		let dx = (currentPosition - dragStart) / pixelsPerImage;
		let index = Math.floor(-dx) % imagesOneLayerNumber;

		if (index < 0) {
			index = imagesOneLayerNumber + index - 1;
		}
		index = (index + dragStartIndex) % imagesOneLayerNumber;
		if (index !== imageIndex) {
			setImageIndex(index);
		}
	};

	const updateImagesLoadedList = (count) => {
		setImagesLoaded(imagesLoaded + count);
	};

	const { layersList, zoom, animate } = props;
	const classes = classNames({
		'react-360': true,
		dragging: dragging,
		animate: animate,
	});

	let resizeWindow = () => {
		const myImageNewRefElem = get(myImageRef, 'current', {});
		const elementWidth = get(myImageNewRefElem, 'offsetWidth', 450);
		const indicatorSize = elementWidth / 1.1;
		if (myImageNewRefElem !== null) {
			if (elementWidth > 0) setImageWidth(indicatorSize);
		} else {
			setImageWidth(imageWidth);
		}
	};

	const returnTableSOrStoolImg = (layer) => {
		return layersList.find(
			(v) => v.name === layer && v.visualType === 'STATIC'
		)?.imageList[0]?.imageUrl;
	};

	const stoolFeetImg = returnTableSOrStoolImg('feet_stool');
	const stoolUpholsteryImg = returnTableSOrStoolImg('upholstery_stool');
	const stoolShellImg = returnTableSOrStoolImg('shell_stool');
	const stoolTable = returnTableSOrStoolImg('table');

	useEffect(() => {
		resizeWindow();
		window.addEventListener('resize', resizeWindow);
		return () => window.removeEventListener('resize', resizeWindow);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageWidth, zoom]);

	return (
		<>
			<SmallLoader
				show={
					numberOfImagesToLoad > imagesOneLayerNumber &&
					numberOfImagesToLoad > imagesLoaded
				}
				text='Loading'
				color='white'
			/>
			<div
				className={classes}
				onMouseDown={handleMouseDown}
				onDragStart={preventDragHandler}
				onClick={() => {}}
				ref={myRef}
				style={{
					transform: `scale(${get(zoom, 'zoomScaleValue')})`,
				}}>
				<div className='react-360__wrapper'>
					{imagesLoaded === numberOfImagesToLoad && stoolTable && (
						<div className='react-360__table'>
							<img
								src={createS3ImagePath(stoolTable, '/')}
								alt='table'
							/>
						</div>
					)}
					{imagesLoaded === numberOfImagesToLoad && (
						<div className='react-360__foot-chair'>
							{stoolFeetImg && (
								<img
									className='react-360__foot-chair__feet'
									src={createS3ImagePath(stoolFeetImg, '/')}
									alt='feet'
								/>
							)}
							{stoolShellImg && (
								<img
									className='react-360__foot-chair__shell'
									src={createS3ImagePath(stoolShellImg, '/')}
									alt='shell'
								/>
							)}
							{stoolUpholsteryImg && (
								<img
									className='react-360__foot-chair__upholstery'
									src={createS3ImagePath(
										stoolUpholsteryImg,
										'/'
									)}
									alt='upholstery'
								/>
							)}
						</div>
					)}
					<div className='react-360__wrapper__center'>
						{layersList.map((layer, index) => {
							const classesLayer = classNames({
								'react-360__wrapper__center__layer': true,
								[`${get(layer, 'name')}`]: true,
								show:
									numberOfImagesToLoad ===
										imagesOneLayerNumber ||
									imagesLoaded === numberOfImagesToLoad,
							});
							if (get(layer, 'visualType') !== 'CAROUSEL')
								return null;
							return (
								<div
									className={classesLayer}
									key={index}
									style={{
										zIndex: get(layer, 'zIndex'),
									}}>
									{get(layer, 'imageList').map(
										(image, index) => {
											return (
												<img
													ref={myImageRef}
													style={{
														opacity:
															index === imageIndex
																? '1'
																: 0,
													}}
													onLoad={(e) => {
														if (imageWidth === 0) {
															setImageWidth(
																e.target
																	.offsetWidth /
																	1.1
															);
														}
														updateImagesLoadedList(
															1
														);
													}}
													className={`react-360__wrapper__center__layer__image ${
														index + 1
													}`}
													src={createS3ImagePath(
														get(image, 'imageUrl'),
														'/'
													)}
													alt={get(image, '_id')}
													key={index}
												/>
											);
										}
									)}
								</div>
							);
						})}
						<div
							className='react-360__wrapper__center__layer indicator'
							style={{
								opacity:
									(get(zoom, 'zoomValue') >= 80 ||
										dragging) &&
									0,
							}}>
							<img
								style={{
									width: imageWidth,
								}}
								src={image360BlackNew}
								alt='360 indicator'
							/>
						</div>
					</div>
					<>
						{layersList.map((layer, index) => {
							if (get(layer, 'name') !== 'backplate') return null;
							return (
								<div
									className='react-360__wrapper__backplate'
									key={index}>
									{get(layer, 'imageList').map(
										(image, index) => {
											return (
												<div
													className='react-360__wrapper__backplate__img'
													style={{
														backgroundImage: `url('${createS3ImagePath(
															get(
																image,
																'imageUrl'
															),
															'/'
														)}')`,
													}}
													key={index}
												/>
											);
										}
									)}
								</div>
							);
						})}
					</>
				</div>
			</div>
		</>
	);
};

React360MultiLayer.propTypes = {
	layersList: PropTypes.array,
	animate: PropTypes.bool,
	zoom: PropTypes.object,
	onChangeZoom: PropTypes.func,
};

React360MultiLayer.defaultProps = {
	layersList: [],
	animate: false,
	zoom: {},
};

export default React360MultiLayer;
