import React from 'react';
import Logo from '../../../ui/Logo';
import Header from '../../../ui/MainHeader/Header';
import { Link } from 'react-router-dom';

import './styles.scss';

const ProductNotFound = () => {
	return (
		<main className='product-not-found'>
			<Header />
			<div className='product-not-found__message'>
				<div className='product-not-found__message__wrap-logo'>
					<Logo size='normal' />
				</div>
				<p className='product-not-found__message__text'>
					We're sorry, the configuration code doesn't exist
				</p>
				<Link to='/'>
					<button className='product-not-found__message__button'>
						Back to catalogue
					</button>
				</Link>
			</div>
		</main>
	);
};

export default ProductNotFound;
