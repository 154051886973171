import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { fetchTxtFile } from '../../data/data';

import './styles.scss';

const ProductInfoDetails = (props) => {
	const { stateDisplayNames, type, state } = props;
	const [arrayOptionsInfo, setArrayOptionsInfo] = useState([]);

	useEffect(() => {
		fetchTxtFile(props).then((response) => setArrayOptionsInfo(response));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const classes = classNames({
		'product-info-details': true,
		[`${type}`]: type,
	});
	return (
		<div className={classes}>
			<h2 className='product-info-details__product-title'>
				{get(stateDisplayNames, 'product')}
			</h2>
			<div className='product-info-details__info'>
				{arrayOptionsInfo.map((option, index) => {
					return (
						<p
							className={`product-info-details__info__text ${index}`}
							key={index}>
							{get(option, 'text')}
						</p>
					);
				})}
			</div>

			<ul className='product-info-details__current-options-list'>
				{get(stateDisplayNames, 'materialtype', '').length > 0 && (
					<li className='product-info-details__current-options-list__item'>
						<label>{get(stateDisplayNames, 'materialtype')}:</label>
						<span>
							{get(stateDisplayNames, 'materialtype') ===
								'Leather' &&
								get(stateDisplayNames, 'leather', '').length >
									0 &&
								get(stateDisplayNames, 'leather') +
									' ' +
									get(
										stateDisplayNames,
										get(
											stateDisplayNames,
											'leather'
										)?.toLowerCase()
									)}
							{get(stateDisplayNames, 'materialtype') ===
								'Fabric' &&
								get(stateDisplayNames, 'fabric', '').length >
									0 &&
								get(stateDisplayNames, 'fabric') +
									' ' +
									get(
										stateDisplayNames,
										get(
											stateDisplayNames,
											'fabric'
										)?.toLowerCase()
									)}
						</span>
					</li>
				)}
				{get(stateDisplayNames, 'shelltype', '').length > 0 && (
					<li className='product-info-details__current-options-list__item'>
						<label>Shell:</label>
						<span>{get(stateDisplayNames, 'shelltype')}</span>
					</li>
				)}
				{get(stateDisplayNames, 'wood', '').length > 0 && (
					<li className='product-info-details__current-options-list__item'>
						<label>Wood style:</label>
						<span>{get(stateDisplayNames, 'wood')}</span>
					</li>
				)}
				{get(stateDisplayNames, 'footlegtype', '').length > 0 && (
					<li className='product-info-details__current-options-list__item'>
						<label>Foot option:</label>
						<span>{get(stateDisplayNames, 'footlegtype')}</span>
					</li>
				)}
				{get(stateDisplayNames, 'tilting', '').length > 0 && (
					<li className='product-info-details__current-options-list__item'>
						<label>Base type:</label>
						<span>{get(stateDisplayNames, 'tilting')}</span>
					</li>
				)}
			</ul>
		</div>
	);
};

ProductInfoDetails.propTypes = {
	stateDisplayNames: PropTypes.object,
	configuratorOptions: PropTypes.array,
	state: PropTypes.object,
	type: PropTypes.oneOf(['pop-up-detail', 'pdf']),
};

ProductInfoDetails.defaultProps = {
	stateDisplayNames: {},
	configuratorOptions: [],
	state: {},
	type: 'pop-up-detail',
};

export default ProductInfoDetails;
