import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { get } from 'lodash';

import './styles.scss';

const ZoomSlider = (props) => {
	const { zoom, onChangeZoom, animate } = props;
	const classes = classNames({
		'zoom-slider': true,
		animate: animate,
	});
	const onZoomIn = () => {
		const newScaleValue = Math.round(
			get(zoom, 'zoomScaleValue') * 100 + 10
		);
		if (get(zoom, 'zoomValue') < 100) {
			onChangeZoom(get(zoom, 'zoomValue') + 10, newScaleValue / 100);
		}
	};
	const onZoomOut = () => {
		const newScaleValue = Math.round(
			get(zoom, 'zoomScaleValue') * 100 - 10
		);

		if (get(zoom, 'zoomValue') > 0) {
			onChangeZoom(get(zoom, 'zoomValue') - 10, newScaleValue / 100);
		}
	};

	return (
		<div className={classes}>
			<div className='zoom-slider__wrapper'>
				<div
					className={`zoom-slider__wrapper__zoom out ${
						get(zoom, 'zoomValue') === 0 ? 'disabled' : ''
					}`}
					onClick={() => onZoomOut()}>
					<Icon iconName='zoom-out' size='normal' />
				</div>
				<div className='zoom-slider__wrapper__slider'>
					<div className='zoom-slider__wrapper__slider__track' />
					<div
						className='zoom-slider__wrapper__slider__thumb'
						style={{
							left: `calc(${get(zoom, 'zoomValue')}% - 10px )`,
						}}></div>
					<input
						orient='vertical'
						type='range'
						min='0'
						max='100'
						step='10'
						value={get(zoom, 'zoomValue')}
						className='zoom-slider__wrapper__slider__input'
						onChange={(e) => {
							onChangeZoom(
								parseInt(e.target.value, 10),
								e.target.value !== '100'
									? 1 + e.target.value / 100
									: 2
							);
						}}
					/>
				</div>
				<div
					className={`zoom-slider__wrapper__zoom in ${
						get(zoom, 'zoomValue') === 100 ? 'disabled' : ''
					}`}
					onClick={() => {
						onZoomIn();
					}}>
					<Icon iconName='zoom-in' size='normal' />
				</div>
			</div>
		</div>
	);
};

ZoomSlider.propTypes = {
	currentValue: PropTypes.string,
};

ZoomSlider.defaultProps = {
	currentValue: '1',
};

export default ZoomSlider;
