import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const dots = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight'];

const SmallLoader = (props) => {
	const { show, text, background, color } = props;
	const classes = classNames({
		'small-loader': true,
		background: background,
		show: show,
		[`${color}`]: color,
	});
	return (
		<div className={classes}>
			<div className='small-loader__wrapper'>
				{dots.map((dot, index) => {
					return (
						<div
							key={index}
							className={`small-loader__wrapper__dot ${dot}`}
						/>
					);
				})}
			</div>
			<span className='small-loader__text'>{text}</span>
		</div>
	);
};

SmallLoader.propTypes = {
	show: PropTypes.bool,
	background: PropTypes.bool,
	text: PropTypes.string,
	color: PropTypes.oneOf(['white', 'black']),
};

SmallLoader.defaultProps = {
	show: true,
	background: false,
	color: 'white',
};

export default SmallLoader;
