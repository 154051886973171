import React from 'react';
import PropTypes from 'prop-types';
import Thumbnail from '../../../ui/Thumbnail';
import classNames from 'classnames';
import { get } from 'lodash';

import './styles.scss';

const Catalogue = (props) => {
	const { products, show } = props;
	const classes = classNames({
		catalogue: true,
		show: show,
	});
	return (
		<div className={classes}>
			{products.map((product, index) => {
				return (
					<Thumbnail
						key={index}
						size='normal'
						shape='squared'
						thumbnailData={product}
						defaultConfig={get(product, 'defaultConfig')}
						type='catalogue-option'
						detail={false}
					/>
				);
			})}
		</div>
	);
};

Catalogue.propTypes = {
	products: PropTypes.array,
};

Catalogue.defaultProps = {
	products: [],
};

export default Catalogue;
