import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ImageExampleComposer from '../../../../ui/ImageExampleComposer';
import useData from '../../../hooks/useData';

import './styles.scss';

const ProductInformation = () => {
	const { data } = useData();
	return (
		<div className='product-information'>
			<h3 className='product-information__title'>
				{get(data, ['stateDisplayNames', 'product'])}
			</h3>
			<h4 className='product-information__subtitle'>
				A perfect relaxing chair by Jahn Aamodt
			</h4>
			<p className='product-information__introduction'>
				This is not just any armchair. It is your armchair. With the
				choice of details, you can show off your personality and reveal
				your character.
			</p>
			<p className='product-information__description'>
				<strong>A fine leather age gracefully</strong>, is made to be
				used and only gets more beautiful with time. The combination of
				leathers and fabrics – both in the armchair and footstool – also
				opens up for many individual choices. Discreet matching or
				daring contrast, there are no right or wrong. Your armchair can
				be anything from a faithful servant of everyday life, to a
				colourful solitaire that turns your living room into a show room
				– Each day.
			</p>
			<ul className='product-information__features'>
				{get(data, ['stateDisplayNames', 'materialtype'], '').length >
					0 && (
					<li className='product-information__features__item'>
						<label>Cover material:</label>
						<span>
							{get(data, ['stateDisplayNames', 'materialtype'])}
						</span>
					</li>
				)}
				{get(data, ['stateDisplayNames', 'shelltype'], '').length >
					0 && (
					<li className='product-information__features__item'>
						<label>Shell:</label>
						<span>
							{get(data, ['stateDisplayNames', 'shelltype'])}
						</span>
					</li>
				)}
				{get(data, ['stateDisplayNames', 'wood'], '').length > 0 && (
					<li className='product-information__features__item'>
						<label>Wood style:</label>
						<span>{get(data, ['stateDisplayNames', 'wood'])}</span>
					</li>
				)}
				{get(data, ['stateDisplayNames', 'footlegtype'], '').length >
					0 && (
					<li className='product-information__features__item'>
						<label>Foot option:</label>
						<span>
							{get(data, ['stateDisplayNames', 'footlegtype'])}
						</span>
					</li>
				)}
			</ul>
			<div className='product-information__image'>
				<ImageExampleComposer images={get(data, 'images')} />
			</div>
		</div>
	);
};

ProductInformation.propTypes = {
	child: PropTypes.string,
};

ProductInformation.defaultProps = {
	child: '',
};

export default ProductInformation;
