import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import React360MultiLayer from '../../components/React360MultiLayer';
import Header from '../../../ui/MainHeader/Header';
import ConfiguratorComposer from '../../../ui/ConfiguratorComposer';
import ZoomSlider from '../../../ui/ZoomSlider';
import DetailView from '../../components/DetailView';
import DetailThumbnailList from '../../../ui/DetailThumbnailList';
import useData from '../../hooks/useData';
import { get } from 'lodash';
import './styles.scss';

const Configurator = () => {
	const [animate, setAnimate] = useState(false);
	const [zoom, setZoom] = useState({ zoomValue: 0, zoomScaleValue: 1 });
	const { code, filter } = useParams();

	const { UpdateStateDataApp, data, dataLoaded, UpdateFilter } = useData();
	const newStateWithCode = {
		productCode: code,
		filter: filter,
	};
	const onChangeZoom = (zoomValue, zoomScaleValue) => {
		setZoom({ zoomValue: zoomValue, zoomScaleValue: zoomScaleValue });
	};

	const updateData = async (state, newSelection) => {
		if (!newSelection) {
			await UpdateStateDataApp(state, 'configurator');
		} else {
			await UpdateStateDataApp({ state, newSelection }, 'configurator');
		}
	};

	const animateShowLoader = () => {
		const loader = setTimeout(() => {
			setAnimate(true);
			setZoom({ zoomValue: 50, zoomScaleValue: 1.5 });
		}, 1500);
		return () => clearTimeout(loader);
	};
	const animateDefault = () => {
		const animate = setTimeout(() => {
			setAnimate(false);
		}, 3500);
		return () => clearTimeout(animate);
	};

	useEffect(() => {
		updateData(newStateWithCode);
		animateShowLoader();
		animateDefault();
		UpdateFilter(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataLoaded, code]);

	if (get(data, 'error')) return <Redirect to='/product-not-found' />;
	const layersList360 = get(data, 'images', []).filter(function (layer) {
		if (get(layer, 'visualType') === 'POI') return null;
		return layer;
	});

	return (
		<main className='configurator'>
			<Header current='configurator-page' />
			<ConfiguratorComposer />
			<div className='configurator__content'>
				<DetailThumbnailList />
				{dataLoaded && (
					<>
						<React360MultiLayer
							layersList={layersList360}
							zoom={zoom}
							animate={animate}
							onChangeZoom={onChangeZoom}
						/>
						<ZoomSlider
							zoom={zoom}
							onChangeZoom={onChangeZoom}
							animate={animate}
						/>
						<DetailView />
					</>
				)}
			</div>
		</main>
	);
};

export default Configurator;
