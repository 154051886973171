import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../ui/Icon';
import useData from '../../../hooks/useData';
import classNames from 'classnames';
import { get } from 'lodash';

import './styles.scss';

const ShareLink = () => {
	const [textCopied, setTextCopied] = useState(false);
	const linkRef = useRef(null);
	const { data } = useData();
	const shareUrl = `${window.location.origin}/${get(data, 'shareLink')}`;

	const classesAlert = classNames({
		'share-link__short-link__alert': true,
		show: textCopied,
	});

	const copyToClipboard = () => {
		const copyUrl = linkRef.current;
		// /* Select the text field */
		copyUrl.select();
		copyUrl.setSelectionRange(0, 99999); /* For mobile devices */

		// /* Copy the text inside the text field */
		document.execCommand('copy');
		setTextCopied(true);

		const alertTimeout = setTimeout(() => {
			setTextCopied(false);
		}, 2000);
		return () => clearTimeout(alertTimeout);
	};

	return (
		<div className='share-link'>
			<div className='share-link__qr-code'>
				<Icon iconName='qr-code' size='large' />
			</div>
			<div className='share-link__config-code'>
				<label>Config code:</label>
				<span>{get(data, 'productCode')}</span>
			</div>
			<div className='share-link__short-link'>
				<input
					type='text'
					className='share-link__short-link__input'
					defaultValue={shareUrl}
					ref={linkRef}
				/>
				<span className={classesAlert}>
					Share link copied to clipboard
				</span>
			</div>
			<button
				className='share-link__copy-link'
				onClick={() => {
					copyToClipboard();
				}}>
				Copy link
			</button>
		</div>
	);
};

ShareLink.propTypes = {
	child: PropTypes.string,
};

ShareLink.defaultProps = {
	child: '',
};

export default ShareLink;
