import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createS3ImagePath } from '../../helpers/images';
import ThumbnailDetailPopUp from '../ThumbnailDetailPopUp';
import useData from '../../businessLogic/hooks/useData';
import { get } from 'lodash';

import './styles.scss';

const Thumbnail = (props) => {
	const {
		shape,
		size,
		thumbnailData,
		labelBg,
		detail,
		type,
		category,
		stateProperty,
		defaultConfig,
		selections,
		optionIndex,
		showName,
		optionFileInfo,
	} = props;

	const { UpdateStateDataApp, data, filter } = useData();
	const currentState = get(data, 'state');
	const mainClass = type !== 'detail' ? 'thumbnail' : 'thumbnail-detail';
	const classes = classNames({
		[`${mainClass}`]: true,
		[`thumbnail-shape--${shape}`]: shape,
		[`thumbnail-size--${size}`]: size,
		'label-bg': labelBg && get(thumbnailData, 'displayName'),
		scene: type === 'scene-option',
		[`${get(thumbnailData, 'visualState')}`]: get(
			thumbnailData,
			'visualState'
		),
	});

	const maskClasses = classNames({
		'thumbnail__image-wrap__mask': true,
		active: get(currentState, stateProperty) === get(thumbnailData, 'name'),
	});

	const updateStateWithOptions = (state, newSelection) => {
		UpdateStateDataApp({ state, newSelection, filter }, 'configurator');
	};
	return (
		<div className={classes}>
			<div
				className={`${mainClass}__image-wrap`}
				title={get(thumbnailData, ['thumbnail', 'alt'])}>
				{
					{
						'catalogue-option': (
							<Link to={`/${filter}/${defaultConfig}`}>
								<img
									className={`${mainClass}__image-wrap__img`}
									src={createS3ImagePath(
										get(thumbnailData, [
											'thumbnailDetail',
											'imageUrl',
										])
									)}
									alt={get(thumbnailData, [
										'thumbnailDetail',
										'alt',
									])}
								/>
							</Link>
						),
						detail: (
							<img
								className={`${mainClass}__image-wrap__img`}
								src={createS3ImagePath(
									get(thumbnailData, [
										'thumbnailDetail',
										'imageUrl',
									])
								)}
								alt={get(thumbnailData, [
									'thumbnailDetail',
									'alt',
								])}
							/>
						),
						'scene-option': (
							<img
								className={`${mainClass}__image-wrap__img`}
								src={createS3ImagePath(
									get(thumbnailData, [
										'thumbnail',
										'imageUrl',
									])
								)}
								alt={get(thumbnailData, ['thumbnail', 'alt'])}
								onClick={() => {
									updateStateWithOptions(currentState, {
										[stateProperty]: get(
											thumbnailData,
											'name'
										),
										filter: filter,
									});
								}}
							/>
						),
						'configurator-option': (
							<div
								className={maskClasses}
								onClick={() => {
									updateStateWithOptions(currentState, {
										[stateProperty]: get(
											thumbnailData,
											'name'
										),
									});
								}}>
								<img
									className={`${mainClass}__image-wrap__img`}
									src={createS3ImagePath(
										get(thumbnailData, [
											'thumbnail',
											'imageUrl',
										])
									)}
									alt={get(thumbnailData, [
										'thumbnail',
										'alt',
									])}
								/>
							</div>
						),
					}[type]
				}
			</div>
			{detail && (
				<div className={`${mainClass}__detail-btn`}>
					<ThumbnailDetailPopUp
						thumbnailData={thumbnailData}
						category={category}
						selections={selections}
						optionIndex={optionIndex}
						stateProperty={stateProperty}
						optionFileInfo={optionFileInfo}
					/>
				</div>
			)}
			{showName &&
				get(thumbnailData, 'displayName') &&
				type !== 'scene-option' && (
					<div className={`${mainClass}__name`}>
						<span>{get(thumbnailData, 'displayName')}</span>
					</div>
				)}
		</div>
	);
};

Thumbnail.propTypes = {
	thumbnailData: PropTypes.object,
	shape: PropTypes.oneOf(['squared', 'rounded']),
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	labelBg: PropTypes.bool,
	detail: PropTypes.bool,
	type: PropTypes.string,
	category: PropTypes.string,
	stateProperty: PropTypes.string,
	selections: PropTypes.array,
	optionIndex: PropTypes.number,
	showName: PropTypes.bool,
};

Thumbnail.defaultProps = {
	shape: 'squared',
	size: 'normal',
	thumbnailSrc: {},
	detail: false,
	optionIndex: 0,
	selections: [],
	showName: true,
};

export default Thumbnail;
