import React from 'react';
import SidebarTitle from '../SidebarTitle';
import SidebarPanel from '../SidebarPanel';
import OptionsBlock from '../Configurator/OptionsBlock';
import ScenesBlock from '../ScenesBlock';
import RandomizerButton from '../RandomizerButton';
import ShareButton from '../ShareButton';
import useData from '../../businessLogic/hooks/useData';
import { get } from 'lodash';

import './styles.scss';

const ConfiguratorComposer = () => {
	const { data, dataLoaded } = useData();

	return (
		<div className='configurator-composer'>
			{dataLoaded && (
				<>
					<SidebarTitle
						product={get(data, ['stateDisplayNames', 'product'])}
						iconPosition='left'
						type='configurator'
					/>
					<SidebarPanel type='configurator'>
						<div className='sidebar-panel__content'>
							<OptionsBlock options={get(data, 'configurator')} />
						</div>
						<div className='sidebar-panel__bottom-options '>
							<div className='sidebar-panel__bottom-options__buttons'>
								<RandomizerButton
									label={false}
									type='button'
									tooltip={true}
									title='Randomize'
								/>
								<ShareButton
									icon='share'
									size='small'
									text='Share / Save'
								/>
							</div>
							<ScenesBlock />
						</div>
					</SidebarPanel>
				</>
			)}
		</div>
	);
};

export default ConfiguratorComposer;
