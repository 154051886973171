import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const SharePics = (props) => {
	return (
		<div className='share-pics'>
			<span>SharePics content</span>
		</div>
	);
};

SharePics.propTypes = {
	child: PropTypes.string,
};

SharePics.defaultProps = {
	child: '',
};

export default SharePics;
