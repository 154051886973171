import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const SharePDF = (props) => {
	return (
		<div className='share-pdf'>
			<span>SharePDF content</span>
		</div>
	);
};

SharePDF.propTypes = {
	child: PropTypes.string,
};

SharePDF.defaultProps = {
	child: '',
};

export default SharePDF;
