import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useData from '../../businessLogic/hooks/useData';
import { get } from 'lodash';

import './styles.scss';

const SwitchButtonText = (props) => {
	const { selections, value } = props;

	const classes = classNames({
		'switch-button-text': true,
	});

	const { UpdateStateDataApp, data } = useData();

	const currentState = get(data, 'state');

	const updateStateWithOptions = (state, newSelection) => {
		UpdateStateDataApp({ state, newSelection }, 'configurator');
	};
	return (
		<div className={classes}>
			{selections.map((selection, index) => {
				const classes = classNames({
					'switch-button-text__item': true,
					active:
						get(selection, 'name') === get(currentState, [value]),
				});
				return (
					<div
						className={classes}
						key={index}
						onClick={() => {
							updateStateWithOptions(currentState, {
								[get(currentState, [value])]: get(
									selection,
									'name'
								),
							});
						}}>
						<span>{get(selection, 'displayName')}</span>
					</div>
				);
			})}
		</div>
	);
};

SwitchButtonText.propTypes = {
	iconName: PropTypes.string,
	size: PropTypes.oneOf(['small', 'normal', 'large']),
};

SwitchButtonText.defaultProps = {
	iconName: 'menu',
	size: 'small',
};

export default SwitchButtonText;
