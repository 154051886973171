import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from '../../Logo';
import Menu from '../Menu';
import MenuAnimateButton from '../MenuAnimateButton';

import './styles.scss';

const Header = (props) => {
	const { current } = props;
	const classes = classNames({
		header: true,
		[`${current}`]: true,
	});
	const [showMenu, setShowMenu] = useState(false);

	function setShowMenuHandle(value) {
		setShowMenu(!value);
	}

	return (
		<header className={classes}>
			<Menu show={showMenu} />
			<Link to={{ pathname: 'https://conform.se' }} target='_blank'>
				<Logo size='small' />
			</Link>
			<div className='header__options'>
				<MenuAnimateButton
					active={showMenu}
					onHandleClick={setShowMenuHandle}
				/>
			</div>
		</header>
	);
};

export default Header;
