import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TabContent from '../TabContent';
import useData from '../../../businessLogic/hooks/useData';
import { get } from 'lodash';

import './styles.scss';

const TabsBlock = (props) => {
	const { selections, accordionIsOpen, currentTabName, currentMaterialName } =
		props;
	const [activeTab, setActiveTab] = useState(currentTabName);
	const refContainer = useRef(null);
	const { SetHeightMultiAccordion } = useData();

	useEffect(() => {
		if (accordionIsOpen) {
			const accordionMain = setTimeout(() => {
				SetHeightMultiAccordion(
					0,
					refContainer.current.offsetHeight + 90
				);
			}, 1500);
			return () => clearTimeout(accordionMain);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab, accordionIsOpen]);

	const onHandleActiveTab = (index) => {
		setActiveTab(index);
	};
	const classes = classNames({
		'tabs-block': true,
	});
	return (
		<div className={classes}>
			<ul className='tabs-block__tabs'>
				{selections.map((selection, index) => {
					const classesTab = classNames({
						'tabs-block__tabs__item': true,
						active: activeTab === get(selection, 'displayName'),
					});
					return (
						<li
							className={classesTab}
							key={index}
							onClick={() => {
								onHandleActiveTab(
									get(selection, 'displayName')
								);
							}}>
							<span>{get(selection, 'displayName')}</span>
						</li>
					);
				})}
			</ul>
			<div ref={refContainer}>
				{selections.map((selection, index) => {
					return (
						<TabContent
							key={index}
							show={activeTab === get(selection, 'displayName')}
							tabName={get(selection, 'displayName')}
							selections={get(selection, 'selections')}
							currentMaterialName={currentMaterialName}
							activeTab={activeTab}
						/>
					);
				})}
			</div>
		</div>
	);
};

TabsBlock.propTypes = {
	size: PropTypes.oneOf(['small', 'normal', 'large']),
	currentTabName: PropTypes.string,
	accordionIsOpen: PropTypes.bool,
};

TabsBlock.defaultProps = {
	size: 'normal',
	currentTabName: 'Leather',
	accordionIsOpen: false,
};

export default TabsBlock;
