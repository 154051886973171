import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SwitchButtonText from '../SwitchButtonText';
import { get } from 'lodash';

import './styles.scss';

const SwitchBlock = (props) => {
	const { option, visualState } = props;
	const classes = classNames({
		'switch-option': true,
		[`${visualState}`]: true,
	});

	return (
		<div className={classes}>
			<h3 className='switch-option__title'>
				{get(option, 'displayName')}
			</h3>
			<div className='switch-option__switch'>
				<SwitchButtonText
					selections={get(option, 'selections')}
					value={get(option, 'name')}
				/>
			</div>
		</div>
	);
};

SwitchBlock.propTypes = {
	child: PropTypes.string,
};

SwitchBlock.defaultProps = {
	child: '',
};

export default SwitchBlock;
