import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import { Link } from 'react-router-dom';
import useData from '../../../businessLogic/hooks/useData';

import './styles.scss';

const NavItem = (props) => {
	const { filter } = useData();
	const { icon, onClickHandle, link, name, active } = props;
	const classes = classNames({
		'nav-item': true,
		active: active,
	});
	return (
		<li
			className={classes}
			onClick={() => {
				if (name !== 'Catalogue') onClickHandle(link, name);
			}}>
			{name === 'Catalogue' ? (
				<Link to={`/${filter}`} className='nav-item__wrap'>
					<div className='nav-item__wrap__icon'>
						<Icon iconName={icon} size='normal' />
					</div>
					<span className='nav-item__wrap__label'>{name}</span>
				</Link>
			) : (
				<div className='nav-item__wrap'>
					<div className='nav-item__wrap__icon'>
						<Icon iconName={icon} size='normal' />
					</div>
					<span className='nav-item__wrap__label'>{name}</span>
				</div>
			)}
		</li>
	);
};

NavItem.propTypes = {
	icon: PropTypes.string,
	name: PropTypes.string,
	link: PropTypes.string,
	active: PropTypes.bool,
};

NavItem.defaultProps = {
	link: '/',
	active: false,
};

export default NavItem;
