import { get } from 'lodash';

export const pageData = {
	menuOptions: [
		{
			name: 'Catalogue',
			icon: 'chairs',
			link: '/',
		},
		{
			name: 'Load configuration code',
			icon: 'qr-code',
			link: 'qr-code',
		},
		{
			name: 'Contact',
			icon: 'contact',
			link: 'contacts',
		},
		{
			name: 'Imprint',
			icon: 'imprint',
			link: 'imprint',
		},
		{
			name: 'Privacy',
			icon: 'account-privacy',
			link: 'account-privacy',
		},
	],
};


const fetchTxtFile = (props) => {
	let arrayOptionsInfo = [];
	let newArrayTextFilesUrl = [];
	let keyNames = Object.keys(get(props, 'state', []));
	for (let key of keyNames) {
		const stateKey = props.state[key];
		if (key !== 'backgrounds') {
			const itemFound = props.configuratorOptions.find(
				({ name }) => name === key
			);
			const selections = get(itemFound, 'selections', []);
			const selectionFound = selections.find(
				({ name }) => name === stateKey
			);
			const fileFound =
				get(selectionFound, 'productInfo') ||
				get(selectionFound, 'optionInfo');
			if (fileFound !== undefined)
				newArrayTextFilesUrl.push({ option: key, fileUrl: fileFound });
			if (selectionFound && selectionFound.hasOwnProperty('selections')) {
				const materialSelections = get(
					selectionFound,
					'selections',
					[]
				);
				for (let i = 0; i < keyNames.length; i++) {
					const materialFound = materialSelections.find(
						({ name }) => name === keyNames[i]
					);
					const materialFileFound =
						get(materialFound, 'productInfo') ||
						get(materialFound, 'optionInfo');
					if (materialFileFound)
						newArrayTextFilesUrl.push({
							option: keyNames[i],
							fileUrl: materialFileFound,
						});
				}
			}
		}
	}

	return new Promise((resolve) => {
		const newStateArray = [...arrayOptionsInfo];
		const requestArray = [];
		for (let i = 0; i < newArrayTextFilesUrl.length; i++) {
			const filePath = get(newArrayTextFilesUrl, [i, 'fileUrl']);
			const textFile = `${window.location.origin}${filePath}`;
			const option = get(newArrayTextFilesUrl, [i, 'option']);
			if (option !== get(arrayOptionsInfo, [i, 'option'])) {
				if (filePath) {
					requestArray.push(
						fetch(textFile)
							.then((response) => response.text())
							.then((text) => {
								const newOptionObj = {
									option: get(newArrayTextFilesUrl, [
										i,
										'option',
									]),
									text: text,
								};

								if(newOptionObj.text.includes('<!DOCTYPE')) {
									newOptionObj.text = 'The first impression of Prime is one of strict elegance, with light curves making the design come to life and bringing character to the room. The image here shows a combination of wood and fabric matched by the footstool. This natural colour palette makes it even easier to breathe out and find peace and harmony after a rough day. Syncro Backup in the back gives you a comfortable and ergonomically correct sitting position at all times.';
								}

								if (option === 'product') {
									newStateArray.splice(0, 0, newOptionObj);
								} else {
									newStateArray.push(newOptionObj);
								}	
							})
					)
				}
			}
		}

		Promise.all(requestArray).then(() => {
			resolve(newStateArray);
		})
	})
};

export { fetchTxtFile };
export default pageData;
